import { useMemo } from 'react';
import {
  Box,
  LinearProgress,
  Skeleton,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import Antminer from 'assets/logos/antminer.svg';
import Whatsminer from 'assets/logos/whatsminer.svg';
import { colors } from 'shared/constants/theme';

interface IProps {
  items: {
    machineType: string;
    machineNumber: string;
  }[];
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#fff',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.lightGreen,
  },
}));

export const MinerChart = ({ items }: IProps): JSX.Element => {
  const data = useMemo(() => {
    const numbers = items.map((item) => +item.machineNumber);
    const highestNumber = Math.max(...numbers);

    return items.map((item) => {
      return {
        ...item,
        percent: (+item.machineNumber / highestNumber) * 100,
        logo: item.machineType === 'Whatsminer' ? Whatsminer : Antminer,
      };
    });
  }, [items]);

  return !items.length ? (
    <Skeleton variant="rectangular" height={300} />
  ) : (
    <>
      {data.map((item) => {
        return (
          <Box
            key={item.machineNumber + item.percent}
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}
            >
              <img src={item.logo} style={{ width: '30px', height: '30px' }} />
              <Typography fontWeight={500}>{item.machineType}</Typography>
              <Typography fontWeight={500}>{item.machineNumber}</Typography>
            </Box>
            <BorderLinearProgress variant="determinate" value={item?.percent} />
          </Box>
        );
      })}
    </>
  );
};
