import { Box, Grid } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { CustomerKpiTable } from 'components/Tables/CustomerKpiTable';

export const KPI = ({ statistics }: any) => {
  return (
    <Box sx={{ marginTop: '70px' }}>
      <SectionHeading name="Cryptocurrencies mining" />
      <Grid
        marginTop={0.5}
        marginBottom={5}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <CustomerKpiTable statistics={statistics} />
      </Grid>
    </Box>
  );
};
