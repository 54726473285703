import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseTable } from 'components/Tables/BaseTable';
import { useAuth } from 'shared/hooks/useAuth';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { columns } from './columns';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import SimulationService from 'shared/services/simulation.service';
import { useNotification } from 'shared/hooks/useNotification';
import { AdminSimulationForm } from 'components/Forms/AdminSimulationForm';
import { GeneratedSimulationForm } from 'components/Forms/AdminGeneratedSimulationForm';
import { SavedSimulationForm } from 'components/Forms/AdminSavedSimulationForm';
import { RedesignedTable } from '../RedesignedTable';

export const AdminSimulation = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userData, setUserData] = useState<any[] | null>(null);
  const [simulations, setSimulations] = useState<any[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [delSimulationID, setDelSimulationID] = useState<string>('');
  const { showSnackbar } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [simulationState, setSimulationState] = useState<any>('empty');
  const [simulationData, setSimulationData] = useState<any>(null);
  const [pdf, setPdf] = useState<string>('');
  const [simulationId, setSimulationId] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [previewSimulationId, setPreviewSimulationId] = useState<any>(null);
  const [staticDataValue, setStaticDataValue] = useState<any>(null);
  const [generateInitialValues, setGenerateInitialValues] = useState<any>(null);

  const { user } = useAuth();

  const fetchSimulations = async () => {
    setSimulations(null);
    try {
      const fetchData =
        user?.role === UserRole.ADMIN
          ? SimulationService.getSimulations
          : SimulationService.getSimulationsAffiliate;
      const response = await fetchData({
        pageNumber: currentPage,
        limit: rowsPerPage,
      });

      const { data, totalPages, staticData } = response;

      setStaticDataValue(staticData);
      setSimulations(data);
      setTotalPages(totalPages);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleEdit = (user: any): void => {
    setShowEditModal(true);
    setUserData(user);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchSimulations();
  }, [currentPage, rowsPerPage]);

  const handleDeleteSimulation = useCallback(async () => {
    setIsLoading(true);
    try {
      if (delSimulationID) {
        await SimulationService.deleteSimulation(delSimulationID);
        fetchSimulations();
        setDelSimulationID('');
        showSnackbar('Successfully Deleted', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [delSimulationID]);

  const selectedSimulationData = useMemo(() => {
    const selectedSimulation = simulations?.find(
      (simulation) => simulation.id === previewSimulationId,
    );

    return {
      data: { ...selectedSimulation, ...staticDataValue },
      hostingOpportunity: selectedSimulation?.hostingOpportunity,
      hardwareOpportunity: selectedSimulation?.hardwareOpportunity,
    };
  }, [previewSimulationId]);

  const handleSimulationModalValue = (currentSimulationState: any) => {
    if (currentSimulationState === 'empty') {
      return (
        <AdminSimulationForm
          setShowModal={setShowModal}
          setIsLoading={setIsLoading}
          setSimulationState={setSimulationState}
          setSimulationData={setSimulationData}
          setClientName={setClientName}
          generateInitialValues={generateInitialValues}
          setGenerateInitialValues={setGenerateInitialValues}
          clientName={clientName}
          handleClose={handleCloseModal}
        />
      );
    }
    if (currentSimulationState === 'generated') {
      return (
        <GeneratedSimulationForm
          setPdf={setPdf}
          setShowModal={setShowModal}
          setIsLoading={setIsLoading}
          simulationData={simulationData}
          setSimulationState={setSimulationState}
          setSimulationId={setSimulationId}
          fetchSimulations={fetchSimulations}
          clientName={clientName}
        />
      );
    }

    if (currentSimulationState === 'done') {
      return (
        <SavedSimulationForm
          simulationId={simulationId}
          pdf={pdf}
          handleClose={handleCloseModal}
          clientName={clientName}
        />
      );
    }

    return <></>;
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setGenerateInitialValues(null);
    setClientName('');
    setSimulationState('empty');
  };

  return (
    <Box marginY="30px">
      <CustomModal
        open={showModal}
        onClose={() => handleCloseModal()}
        maxWidth="700px"
        backdropOpen={isLoading}
      >
        {handleSimulationModalValue(simulationState)}
      </CustomModal>
      <RedesignedTable
        tableName="Simulations"
        items={simulations}
        columns={columns(setPreviewSimulationId, setShowEditModal)}
        setShowModal={setShowModal}
        handleEdit={handleEdit}
        show={true}
        setCurrentPage={setCurrentPage}
        onRowDelete={setDelSimulationID}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        isEditable={false}
        addButtonText="Create Simulation"
      />
      <CustomModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        maxWidth="700px"
        backdropOpen={isLoading}
      >
        <GeneratedSimulationForm
          setShowModal={setShowEditModal}
          editData={userData}
          onFinish={fetchSimulations}
          setIsLoading={setIsLoading}
          setPdf={setPdf}
          simulationData={selectedSimulationData}
          setSimulationState={setSimulationState}
          setSimulationId={setSimulationId}
          fetchSimulations={fetchSimulations}
          clientName={clientName}
        />
      </CustomModal>
      <CustomModal
        open={!!delSimulationID}
        onClose={() => setDelSimulationID('')}
      >
        <Box className="container">
          <DeleteModal
            title="Simulation"
            onClick={handleDeleteSimulation}
            onCancel={() => setDelSimulationID('')}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};
