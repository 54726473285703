import { NorthEast } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { getDynamicStyles } from 'components/Cards/StatisticsCard/styles';
import moment from 'moment/moment';

export const StatisticsCard = (props: any) => {
  const { value, title, currency, small = false, colored = false } = props;

  return (
    <InfoCard
      styles={getDynamicStyles({ small, colored })}
      showDivider={false}
      showTime={false}
      subtitle={title}
      headerColor={colored && '#ffffff'}
      title=""
      content={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {value !== null ? (
            <>
              <Typography
                sx={{
                  fontSize: {
                    xl: '32px',
                    lg: '32px',
                    md: '25px',
                    sm: '35px',
                    xs: '28px',
                  },
                }}
                color={colored ? 'white' : 'secondary'}
                variant="h3"
                fontWeight={500}
              >
                {`${value?.toLocaleString('en-EN')} ${currency}`}
              </Typography>
              {!small && (
                <Typography
                  sx={{
                    fontSize: {
                      xl: '15px',
                      lg: '15px',
                      md: '10px',
                      sm: '15px',
                      xs: '12px',
                    },
                  }}
                  color={colored ? 'white' : '#828282'}
                  alignItems="center"
                  display="flex"
                >
                  {moment().format('MMMM yyyy')}
                  <NorthEast />
                </Typography>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" height={80} width="100%" />
          )}
        </Box>
      }
    />
  );
};
