import { colors } from 'shared/constants/theme';

export const Linkedin = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_306_52706)">
        <g clip-path="url(#clip1_306_52706)">
          <path
            d="M7.12031 20.7016H7.37031V20.4516V8.99531V8.74531H7.12031H3.55781H3.30781V8.99531V20.4516V20.7016H3.55781H7.12031ZM20.4516 20.7016H20.7016V20.4516V14.1656C20.7016 12.6179 20.5373 11.1906 19.8796 10.1475C19.2053 9.07799 18.0452 8.45938 16.1813 8.45938C14.7417 8.45938 13.6632 9.0846 13.0187 9.8284V8.99531V8.74531H12.7687H9.35625H9.10625V8.99531V20.4516V20.7016H9.35625H12.9094H13.1594V20.4516V14.7891C13.1594 14.0458 13.2316 13.3661 13.5005 12.8769C13.6317 12.6382 13.8087 12.447 14.0491 12.3136C14.291 12.1793 14.6119 12.0953 15.0422 12.0953C15.4593 12.0953 15.7507 12.1924 15.9596 12.3383C16.169 12.4846 16.317 12.6951 16.421 12.9584C16.6347 13.4996 16.6437 14.2084 16.6437 14.8828V20.4516V20.7016H16.8937H20.4516ZM1.77187 0.25H22.2234C23.0716 0.25 23.75 0.917992 23.75 1.72969V22.2703C23.75 23.0778 23.0712 23.75 22.2234 23.75H1.77187C0.925447 23.75 0.25 23.079 0.25 22.2656V1.72969C0.25 0.917143 0.924556 0.25 1.77187 0.25ZM3.02188 5.37187C3.02188 6.64938 4.05761 7.68438 5.33906 7.68438C6.61657 7.68438 7.65156 6.64395 7.65156 5.37187C7.65156 4.09474 6.6162 3.05937 5.33906 3.05937C4.05761 3.05937 3.02188 4.09437 3.02188 5.37187Z"
            fill={colors.lightGrey}
            stroke={colors.lightGrey}
            stroke-width="0.5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_306_52706">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_306_52706">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
