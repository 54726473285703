import { Box, Button, Typography } from '@mui/material';
import MarkIcon from 'assets/images/markIcon.svg';
import {
  tableRowStyles,
  titleStyles,
  subTitleStyles,
  modalTitleStyles,
  tableTitleStyles,
  buttonsContainerStyles,
  downloadButtonStyles,
  finishButtonStyles,
} from './styles';
import { downloadFile } from 'shared/utils/fileDownloader';
import moment from 'moment';
import { CheckMark } from 'components/CheckMark';

export const SavedSimulationForm = ({
  handleClose,
  pdf,
  simulationId,
  clientName,
}: any): JSX.Element => {
  return (
    <Box className="container">
      <Box
        className="user-content"
        sx={{
          padding: '30px 50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography className="user-from-title" sx={modalTitleStyles}>
          Simulation has been generate successfully!
        </Typography>
        <CheckMark />
        <Typography sx={tableTitleStyles}>Simulation details</Typography>
        <Box
          sx={{
            borderRadius: '16px',
            border: '1px solid #EAEAEA',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...tableRowStyles,
              borderBottom: '1px solid #EAEAEA',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
            }}
          >
            <Typography sx={titleStyles}>Client name</Typography>
            <Typography sx={subTitleStyles}>{clientName}</Typography>
          </Box>
          <Box sx={{ ...tableRowStyles, borderBottom: '1px solid #EAEAEA' }}>
            <Typography sx={titleStyles}>Simulation ID</Typography>
            <Typography sx={subTitleStyles}>{simulationId}</Typography>
          </Box>
          <Box
            sx={{
              ...tableRowStyles,
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
            }}
          >
            <Typography sx={titleStyles}>Date</Typography>
            <Typography sx={subTitleStyles}>
              {moment().format('DD.MM.YYYY')}
            </Typography>
          </Box>
        </Box>
        <Box sx={buttonsContainerStyles}>
          <Button
            sx={finishButtonStyles}
            onClick={() => handleClose()}
            type="button"
          >
            Finish
          </Button>
          <Button
            sx={downloadButtonStyles}
            onClick={() => {
              downloadFile(pdf);
              handleClose();
            }}
          >
            Download PDF
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
