import { useEffect, useState } from 'react';
import { columns } from './columns';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BaseTable } from 'components/Tables/BaseTable';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import ContractsService from 'shared/services/contracts.service';
import { useNotification } from 'shared/hooks/useNotification';

export const ContractsTable = (props: any) => {
  const { contracts: filteredContracts, currencyValue } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [contracts, setContracts] = useState<any[] | null>(null);
  const { customerId } = useParams();
  const { showSnackbar } = useNotification();

  const fetchContracts = async (search = '') => {
    setContracts(null);
    try {
      const { contracts, totalPages } = await ContractsService.getContracts({
        pageNumber: currentPage,
        limit: rowsPerPage,
        search,
        contractIds: filteredContracts,
        customerId,
        currency: currencyValue,
      });
      setContracts(contracts);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchContracts();
  }, [filteredContracts]);

  return (
    <Box
      sx={{
        marginTop: '20px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderRadius: '20px',
        padding: '20px',
      }}
    >
      <RedesignedTable
        items={contracts}
        columns={columns}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        fetchData={fetchContracts}
        searchable={true}
        wrapped={false}
      />
    </Box>
  );
};
