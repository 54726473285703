import { Box, Grid, Typography } from '@mui/material';
import { BitcoinInfoCard } from 'components/Cards/BitcoinInfoCard';
import { threeRowGridItem } from '../styles';
import { SectionHeading } from 'components/SectionHeading';
import { ChainCard } from './ChainCard';
import moment from 'moment';

export const Chain = (props: any) => {
  const { data, currency, currencyValue, coinAbb } = props;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionHeading
          sx={{
            color: '#fff',
          }}
          name={`${currency} Chain`}
        />
        <Typography marginTop={3} marginBottom={1} color="#BDBDBD">
          {moment().format(`MMM D, HH:mm [UTC]`)}
        </Typography>
      </Box>
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={2}>
        <Grid {...threeRowGridItem}>
          <BitcoinInfoCard
            showFilter
            styles={{
              height: '550px',
            }}
            subtitle="Revenue"
            title=""
            coinAbb={coinAbb}
            currency={currency}
            currencyValue={currencyValue}
          />
        </Grid>
        <Grid {...threeRowGridItem}>
          <ChainCard type="revenue" data={data} />
        </Grid>
        <Grid {...threeRowGridItem}>
          <ChainCard type="difficulty" data={data} />
        </Grid>
      </Grid>
    </>
  );
};
