import { AdminSimulation } from 'components/Tables/AdminSimulationTable';
import { Layout } from 'components/Layout';
import { Box } from '@mui/material';

export const Simulation = () => {
  return (
    <Layout backgroundHeight="600px">
      <Box sx={{ minHeight: '100vh' }}>
        <AdminSimulation />
      </Box>
    </Layout>
  );
};
