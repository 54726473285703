import { colors } from 'shared/constants/theme';

export const LockIcon = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.005 21.0036H7.00083C6.47013 21.0038 5.96113 20.793 5.58587 20.4177C5.21061 20.0425 4.99985 19.5335 5 19.0028V11.9999C4.99985 11.4692 5.21061 10.9602 5.58587 10.5849C5.96113 10.2096 6.47013 9.99888 7.00083 9.99902H17.005C17.5357 9.99888 18.0447 10.2096 18.42 10.5849C18.7952 10.9602 19.006 11.4692 19.0058 11.9999V19.0028C19.006 19.5335 18.7952 20.0425 18.42 20.4177C18.0447 20.793 17.5357 21.0038 17.005 21.0036V21.0036Z"
      stroke={props.color ? props.color : colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0369 15.4645C12.0517 15.4793 12.0561 15.5016 12.0481 15.521C12.0401 15.5404 12.0212 15.553 12.0002 15.553C11.9792 15.553 11.9603 15.5404 11.9523 15.521C11.9443 15.5016 11.9487 15.4793 11.9635 15.4645V15.4645C11.9838 15.4443 12.0166 15.4443 12.0368 15.4645"
      stroke={props.color ? props.color : colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4377 17.0022V14.001"
      stroke={props.color ? props.color : colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 9.99901V6.99776V6.99776C8.0001 4.78774 9.79165 2.99619 12.0017 2.99609V2.99609C14.2117 2.99619 16.0032 4.78774 16.0033 6.99776V6.99776V9.99901"
      stroke={props.color ? props.color : colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.59938 15.4645C8.61421 15.4793 8.61865 15.5016 8.61062 15.521C8.6026 15.5404 8.58368 15.553 8.56271 15.553C8.54173 15.553 8.52282 15.5404 8.51479 15.521C8.50677 15.5016 8.5112 15.4793 8.52604 15.4645V15.4645C8.54629 15.4443 8.57908 15.4443 8.59933 15.4645"
      stroke={props.color ? props.color : colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
