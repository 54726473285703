import { columns } from './columns';
import { RedesignedTable } from 'components/Tables/RedesignedTable';

export const TransactionsTable = (props: any) => {
  const { transactions } = props;

  return (
    <RedesignedTable paginated={false} items={transactions} columns={columns} />
  );
};
