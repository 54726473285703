import { colors } from 'shared/constants/theme';
import Elipse from 'assets/images/elipse.png';

type GetDynamicStyles = {
  colored: boolean;
  small: boolean;
};

export const getDynamicStyles = ({ colored, small }: GetDynamicStyles) => {
  const styles: any = {
    height: small ? 'auto' : '175px',
  };

  if (colored) {
    styles.background = colors.lightGreen;
    styles.backgroundImage = `url(${Elipse})`;
    styles.backgroundSize = '250px 250px';
    styles.backgroundRepeat = 'no-repeat';
    styles.backgroundPosition = 'right 0 top -50px';
    styles.border = 'none';
    styles.zIndex = 5;
  }

  return styles;
};
