import { colors } from 'shared/constants/theme';

export const styles = {
  container: {
    height: '540px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      backgroundColor: '#1D4FBB',
    },
  },
  iconContainer: {
    backgroundColor: 'rgba(29, 79, 187, 0.05)',
    padding: '20px',
    borderRadius: '50%',
    svg: {
      width: '62px',
      height: '62px',
    },
  },
  title: {
    textAlign: 'center',
    maxWidth: '441px',
    fontSize: '24px',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#7B7B7B',
  },
  button: {
    height: '56px',
    width: '100%',
    backgroundColor: `${colors.lightGreen} !important`,
  },
};
