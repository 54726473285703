import { Statistics } from 'components/Statistics';
import { useMemo } from 'react';
import { SectionHeading } from 'components/SectionHeading';
import { DashboardStatistics } from 'shared/types/dashboard';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

type Props = {
  statistics?: DashboardStatistics;
};
export const Operations = ({ statistics }: Props) => {
  const statisticsData = useMemo(
    () => [
      {
        title: 'Total Investments',
        value: statistics
          ? `$${statistics.totalInvestment.toLocaleString('en-EN', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : null,
        currency: 'USD',
      },
      {
        title: 'Total Bitcoin hashrate',
        value: statistics ? statistics.bitcoinHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
      {
        title: 'Total Kaspa hashrate',
        value: statistics ? statistics.kaspaHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
    ],
    [statistics],
  );

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionHeading
          sx={{
            color: '#fff',
          }}
          name="Your operations"
        />
        <Typography marginTop={3} marginBottom={1} color="#BDBDBD">
          {moment().format(`MMM D, HH:mm [UTC]`)}
        </Typography>
      </Box>
      <Statistics statistics={statisticsData} />
    </>
  );
};
