import { useState } from 'react';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Progress } from 'components/Progress';
import { useNotification } from 'shared/hooks/useNotification';
import * as yup from 'yup';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import AuthService from 'shared/services/auth.service';
import { useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { EmailIcon } from 'components/Icons/Email';
import { authButtonStyles } from 'shared/styles/button';

export const schema = yup.object().shape({
  email: yup.string().min(6).required('Please enter your email'),
});

export const EmailConfirmation = (props: any) => {
  const { setStep, setEmail } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();

  const { showSnackbar } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (payload: any) => {
    setIsLoading(true);
    try {
      await AuthService.forgotPassword(payload);
      showSnackbar('Email with the link has been sent', 'success');
      setEmail(payload.email);
      setStep('confirmation');
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="contentLogin" onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: '15px' }}>
          <Typography
            color=""
            variant="h2"
            sx={{
              color: '#fff',
              fontSize: '48px',
              fontWeight: '600',
              textAlign: 'start',
            }}
          >
            Forgot password?
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: '30px' }}>
          <Typography variant="h3" sx={{ color: 'white', fontSize: '12px' }}>
            Enter your Email and get code to verification.
          </Typography>
        </Box>

        <Box className="dataContainer">
          <Box className="dataBox">
            <ControlledTextField
              control={control}
              name="email"
              error={errors}
              placeholder="Email"
              dark
              textFieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          width: '100%',
        }}
      >
        <Button
          sx={authButtonStyles}
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
        >
          {!isLoading ? 'Send' : <Progress color="inherit" size={20} />}
        </Button>
        <Button
          sx={{ marginTop: 2, bgcolor: '#333333', ...authButtonStyles }}
          variant="contained"
          type="submit"
          fullWidth
          onClick={() => navigate(routesMapping.login)}
        >
          Login
        </Button>
      </Box>
    </form>
  );
};
