export const CompanyIcon = (props: any) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.332 35.0066V6.6615C13.332 5.74064 14.0785 4.99414 14.9994 4.99414H25.0036C25.9244 4.99414 26.6709 5.74064 26.6709 6.6615V35.0066"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.0077 35.0061V13.1854C35.0077 12.3446 34.3261 11.6631 33.4854 11.6631H26.6709"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3329 11.6631H6.51839C5.67765 11.6631 4.99609 12.3446 4.99609 13.1854V35.0061"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6709 16.6657H30.8393"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6709 21.6677H30.8393"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6709 26.6706H30.8393"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16309 16.6657H13.3315"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16309 21.6677H13.3315"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16309 26.6706H13.3315"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.501 21.6677H22.5031"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.501 26.6706H22.5031"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.501 16.6657H22.5031"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.501 11.6638H22.5031"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0007 31.6719V35.0066"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.6753 35.0065H3.32812"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="1.90476"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
