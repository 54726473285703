import { Skeleton } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { defaultLineChartStyles } from 'components/Charts/styles';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { colors } from 'shared/constants/theme';
import { formatBtc } from 'shared/utils/formatter';

export const BitcoinTransactions = (props: any) => {
  const { chart, coinAbb } = props;

  const data = {
    labels: chart ? chart.map((d: any) => d.timestamp) : [],
    datasets: [
      {
        label: `${coinAbb} Price`,
        borderRadius: 5,
        borderColor: colors.lightGreen,
        backgroundColor: colors.lightGreen,
        pointBackgroundColor: colors.lightGreen,
        data: chart ? chart.map((d: any) => d.value) : [],
      },
    ],
  };

  return (
    <InfoCard
      subtitle={`${coinAbb} transaction`}
      title=""
      styles={{ height: '600px' }}
      fullHeight
      content={
        chart ? (
          <Bar
            data={data}
            options={{
              maintainAspectRatio: false,
              interaction: {
                mode: 'index',
                intersect: false,
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: (value) => `${formatBtc(value, 4)} ${coinAbb}`,
                  },
                },
                x: {
                  ticks: {
                    callback(tickValue) {
                      return moment(data.labels[tickValue]).format('MMM DD');
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: (item) => {
                      const label = item[0].label;
                      return moment(label).format('ddd, MMM DD');
                    },

                    label: (item) => `${item.formattedValue} ${coinAbb}`,
                  },
                  ...defaultLineChartStyles.plugins.tooltip,
                },
              },
            }}
          />
        ) : (
          <Skeleton variant="rectangular" height={400} />
        )
      }
    />
  );
};
