import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Button, IconButton, Link } from '@mui/material';
import styles from './styles';
import { Linkedin } from 'components/Icons/Linkedin';
import { Twitter } from 'components/Icons/Twitter';
import { Instagram, KeyboardArrowRight } from '@mui/icons-material';
import LayoutNavigateBar from '../LayoutNavigateBar';
import { LogoLink } from '../LogoLink';
import { colors } from 'shared/constants/theme';

export const Footer = () => {
  const [anchorElMobileMenu, setAnchorElMobileMenu] =
    useState<null | HTMLElement>(null);

  const handleOpenMobileRoutes = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setAnchorElMobileMenu(event.currentTarget);
  };

  const handleCloseMobileRoutes = () => setAnchorElMobileMenu(null);

  const logos = [
    {
      icon: <Linkedin />,
      link: 'https://www.linkedin.com/company/hearstio/',
    },
    {
      icon: (
        <Instagram
          sx={{
            color: 'white',
            fontSize: 25,
          }}
        />
      ),
      link: 'https://www.instagram.com/hearst.io/',
    },
    {
      icon: <Twitter />,
      link: 'https://twitter.com/hearst_io',
    },
  ];

  return (
    <AppBar position="static" elevation={0} sx={styles.appBar}>
      <Container sx={styles.container}>
        <Toolbar disableGutters>
          <Box sx={styles.headerContainer}>
            <>
              <IconButton
                onClick={handleOpenMobileRoutes}
                sx={styles.mobileMenuButton}
              >
                <MenuIcon
                  sx={{
                    color: 'white',
                  }}
                />
              </IconButton>
              <Menu
                sx={styles.mobileMenuInnerPaper}
                anchorEl={anchorElMobileMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElMobileMenu)}
                onClose={handleCloseMobileRoutes}
              >
                <LayoutNavigateBar />
              </Menu>
            </>
            <Box sx={styles.headerLeftContainer}>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                    md: 'block',
                    lg: 'block',
                    xl: 'block',
                  },
                }}
              >
                <LogoLink />
              </Box>
              <Box display="flex" columnGap={2} sx={styles.tabs}>
                <LayoutNavigateBar />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 2,
              }}
            >
              {logos.map((value) => (
                <Link
                  href={value.link}
                  sx={{
                    display: 'flex',
                  }}
                  target="blank"
                >
                  {value.icon}
                </Link>
              ))}
              <Button
                sx={{
                  borderColor: colors.lightGrey,
                  borderRadius: 2,
                }}
                endIcon={<KeyboardArrowRight />}
                variant="outlined"
                href="mailto:customers@hearstcorporation.io"
              >
                Contact us
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
