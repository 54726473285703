export const existingUsers = [
  'jean.guillou@akt.io',
  'contact@belouga.io',
  'r.lahouti@gmail.com',
  'jeremy@swissborg.com',
  'democustomer@hearst.com',
  'sasricardo.antibes@gmail.com',
  'ir@t-ruggiero.ch',
  'Mining@hearstcorporation.io',
  'dpstehr@gmail.com',
  'm.mobetie@hearstcorporation.io',
  'Phiblanco@gmail.com',
  'skyba.ievgenia@gmail.com',
  'pino@pinospitaleri.com',
  'nejko@me.com',
];
