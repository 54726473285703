import { colors } from 'shared/constants/theme';

export const EmailIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 9.74902L10.4267 11.5534C11.3924 12.1488 12.6113 12.1488 13.5771 11.5534L16.5037 9.74902"
      stroke={colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="2.99609"
      y="4.99707"
      width="18.0075"
      height="14.0058"
      rx="3"
      stroke={colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
