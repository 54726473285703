import { useEffect, useMemo, useState } from 'react';
import { Layout } from 'components/Layout';
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import DashboardService from 'shared/services/dashboard.service';
import InvoicesService from 'shared/services/invoices.service';
import moment from 'moment';
import { SectionHeading } from 'components/SectionHeading';
import { useParams } from 'react-router-dom';
import { AllInvoicesTable } from 'components/Tables/InvoicesTable/AllInvoicesTable';
import { colors } from 'shared/constants/theme';

const Invoicing = () => {
  const [contractState, setContractState] = useState<any>([]);
  const [contractData, setContractData] = useState<any>([]);
  const [dates, setDates] = useState<any>([]);
  const [dateState, setDateState] = useState<any>([]);
  const [filterDate, setFilterDate] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({
    contracts: [],
  });
  const { customerId } = useParams();

  const subAccountUserIds = useMemo(
    () => filterData.contracts.map((contract: any) => contract.name).sort(),
    [filterData.contracts],
  );

  const getDashboardFilterData = async () => {
    try {
      const data = await DashboardService.getDashboardFilterData({
        customerId,
      });
      return setFilterData(data);
    } catch (err: any) {}
  };

  const getInvoiceFilterData = async () => {
    try {
      const { dates } = await InvoicesService.getInvoicesFilterDate({
        customerId,
      });
      return setFilterDate(dates);
    } catch (err: any) {}
  };

  useEffect(() => {
    getDashboardFilterData();
    getInvoiceFilterData();
  }, []);

  const handleContractChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setContractState(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDateChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setDateState(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Layout backgroundHeight="800px">
      <Box sx={{ minHeight: '100vh' }}>
        <Box
          sx={{
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'block',
              xs: 'block',
            },
            justifyContent: 'space-between',
            alignItems: {
              sm: 'baseline',
              xs: 'baseline',
            },
          }}
        >
          <SectionHeading
            sx={{
              color: '#fff',
              marginBottom: '32px',
            }}
            name="Invoicing"
          />
          <Box
            sx={{
              display: {
                xl: 'flex',
                lg: 'flex',
                md: 'flex',
                sm: 'block',
                xs: 'block',
              },
              columnGap: 2,
              width: {
                xl: 'unset',
                lg: 'unset',
                md: '50%',
                sm: '50%',
                xs: '50%',
              },
            }}
          >
            <FormControl sx={{ width: 300 }} size="medium">
              <Select
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'unset',
                    color: '#828282',
                  },
                  '& .MuiInputBase-input': {
                    border: 'unset',
                    color: '#828282',
                  },
                  bgcolor: colors.lightGrey,
                  borderRadius: '10px',
                  marginBottom: '20px',
                }}
                multiple
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected: any) => {
                  if (selected.length === 0) return 'Dates';
                  return selected
                    .map((s: any) => moment(s).format('MM-YYYY'))
                    .join(', ');
                }}
                displayEmpty
                value={dateState}
                onChange={handleDateChange}
                onClose={() => setDates(dateState)}
              >
                {filterDate.map((date: any) => (
                  <MenuItem
                    key={date}
                    value={date}
                    sx={{ bgcolor: 'transparent', width: '100%' }}
                  >
                    <Checkbox checked={dateState.indexOf(date) > -1} />
                    <ListItemText primary={moment(date).format('MM-YYYY')} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 300 }} size="medium">
              <Select
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'unset',
                    color: '#828282',
                  },
                  '& .MuiInputBase-input': {
                    border: 'unset',
                    color: '#828282',
                  },
                  bgcolor: colors.lightGrey,
                  borderRadius: '10px',
                }}
                multiple
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected: any) => {
                  if (selected.length === 0) return 'All Contracts';
                  return selected.join(', ');
                }}
                displayEmpty
                value={contractState}
                onChange={handleContractChange}
                onClose={() => setContractData(contractState)}
                label="All Contracts"
              >
                {subAccountUserIds.map((contract: any) => (
                  <MenuItem
                    key={contract}
                    value={contract}
                    sx={{ bgcolor: colors.lightGrey, width: '100%' }}
                  >
                    <Checkbox checked={contractState.indexOf(contract) > -1} />
                    <ListItemText primary={contract} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <AllInvoicesTable contracts={contractData} dates={dates} />
      </Box>
    </Layout>
  );
};

export default Invoicing;
