import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';
import MachinesService from 'shared/services/machines.service';
import FilesService from 'shared/services/files.service';
import { useNotification } from 'shared/hooks/useNotification';
import { VisuallyHiddenInput } from 'components/VisuallyHiddenInput';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import './index.css';

const schema = yup.object().shape({
  id: yup.string(),
  type: yup.string().required(),
  cost: yup.number().required(),
});

export const EventLogForm = ({
  setShowModal,
  editData,
  setMachineAdded,
  onFinish,
}: any): JSX.Element => {
  const { id } = useParams();

  const { showSnackbar } = useNotification();
  const [file, setFile] = useState<any>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (eventLogData: any) => {
    const payload: any = {
      ...eventLogData,
      document: '',
      machineId: id,
    };

    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      const { fileName } = await FilesService.uploadFile(fileFormData);
      payload.document = fileName;
    }

    if (editData && editData.document) payload.document = editData.document;

    try {
      if (editData?.id) {
        delete payload?.id;
        await MachinesService.updateMachineEventLog(editData.id, payload);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await MachinesService.createMachineEventLog(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
      setMachineAdded(true);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  const eventLogTypeOptions = [
    {
      value: 'Scheduled_Maintenance',
      name: 'Scheduled Maintenance',
    },
    {
      value: 'Failure',
      name: 'Failure',
    },
    {
      value: 'Repaired_On_Site',
      name: 'Repaired On Site',
    },
    {
      value: 'Repaired_At_SC',
      name: 'Repaired At SC',
    },
    {
      value: 'Repaired_Under_Warranty',
      name: 'Repaired Under Warranty',
    },
  ];

  return (
    <>
      <Box className="containerContract">
        <Typography className="contractTitle">
          {editData?.id ? 'Edit' : 'Add'} Event Log
        </Typography>
        <form className="contract-form-c" onSubmit={handleSubmit(onSubmit)}>
          {editData?.id && (
            <ControlledTextField
              disabled
              control={control}
              defaultValue={editData?.id ?? ''}
              name="id"
              label="ID"
              error={errors}
            />
          )}
          <ControlledSelect
            control={control}
            name="type"
            defaultValue={editData?.type ?? ''}
            label="Type"
            error={errors}
            required
            options={eventLogTypeOptions}
          />
          <ControlledTextField
            control={control}
            required
            type="number"
            defaultValue={editData?.cost ?? 0}
            name="cost"
            label="Cost"
            error={errors}
            placeholder="Cost"
          />

          <div className="wrapInput">
            <p className="label">Document</p>
            <Button
              sx={{ bgcolor: '#008000' }}
              component="label"
              variant="contained"
              startIcon={<CloudUpload />}
            >
              Upload Document
              <VisuallyHiddenInput
                onChange={(e) => setFile(e.target.files![0])}
                accept="application/pdf"
                type="file"
              />
            </Button>
            {file && (
              <Box display="flex" gap={1} alignItems="center" mt={1}>
                {file.name.length > 18
                  ? `${file.name.substring(0, 18)}...`
                  : file.name}
                <IconButton
                  onClick={() => setFile(null)}
                  aria-label="delete"
                  sx={{
                    '&:hover': {
                      background: 'white !important',
                    },
                  }}
                >
                  <Delete sx={{ color: '#008000' }} />
                </IconButton>
              </Box>
            )}
          </div>
          <Button className="submitButton" type="submit">
            {!editData?.id ? 'Add Event Log' : 'Edit Event Log'}
          </Button>
        </form>
      </Box>
    </>
  );
};
