import { colors } from 'shared/constants/theme';

export const buttonsContainerStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
};

export const cancelButtonStyles = {
  marginRight: '10px',
  background: '#eeeeee',
  color: 'black',
  borderRadius: '10px',
  '&.MuiButtonBase-root:hover': {
    bgcolor: '#eeeeee',
  },
};

export const submitButtonStyles = {
  background: colors.lightGreen,
  color: 'white',
  borderRadius: '10px',
  padding: '0px 20px',
  '&.MuiButtonBase-root:hover': {
    bgcolor: '#1d4fbb',
  },
};

export const chainTableContainerStyles = {
  width: '100%',
  display: 'flex',
  border: '1px solid #d6dbdf',
  borderRadius: '6px',
  backgroundColor: '#f3f7fa',
};

export const chainTableContainerPadding = {
  padding: '20px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
};

export const chainDataStyles = {
  width: '42%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const chainDataHeaderStyles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
};

export const chainDataHeaderElements = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '9px',
  fontWeight: '600',
  color: '#ffffff',
  padding: '5px 0',
  width: '70px',
  borderRadius: '6px',
  backgroundColor: colors.lightGreen,
};

export const chainDataElement = {
  display: 'flex',
  width: '222px',
  justifyContent: 'space-around',
  border: `1px solid ${colors.lightGreen}`,
  borderRadius: '4px',
  padding: '5px 0',
};

export const chainDataElementText = {
  fontSize: '9px',
  fontWeight: '500',
  textAlign: 'center',
};

export const sectionHeaderStyles = {
  width: '100%',
  fontWeight: '500',
  fontSize: '18px',
};

export const sectionContainerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  marginTop: '20px',
};
