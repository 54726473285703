import { Avatar, Box } from '@mui/material';

export const columns = (usdFarmingValue: any) => {
  const formatCurrencyWithThreshold = (value: any) => {
    if (value < 0.01) {
      return '<$0.01';
    }
    return `$${value.toFixed(2)}`;
  };

  return [
    {
      id: 'pool',
      label: 'Pool',
      minWidth: 180,
      width: 500,
      align: 'left',
      sortable: false,
      render: (token: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex' }}>
            {token.logos.map((url: any, index: number) => {
              return (
                <Avatar
                  key={index}
                  sx={{
                    width: '30px',
                    height: '30px',
                  }}
                  src={url}
                />
              );
            })}
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            {token.optimizedSymbols.join('+')}
          </Box>
        </Box>
      ),
    },
    {
      id: 'balance',
      label: 'Balance',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: (token: any) => (
        <Box>
          {token?.balance.map((balance: any) => {
            return (
              <Box>
                <div dangerouslySetInnerHTML={{ __html: balance }}></div>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      id: 'value',
      label: 'USD Value',
      minWidth: 120,
      align: 'right',
      sortable: false,
      render: () => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '6px',
            width: '100%',
          }}
        >
          {formatCurrencyWithThreshold(usdFarmingValue)}
        </Box>
      ),
    },
  ];
};
