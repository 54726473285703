import { Box, Typography } from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { EventCircle } from 'components/Icons/EventCircle';
import moment from 'moment';

const getColor = (type: string) => {
  const colors: any = {
    Maintenance: '#9B51E0',
    Disruption: '#F2994A',
    Deployment: '#95DA3E',
  };

  return colors[type];
};

export const columns: ColumnsType[] = [
  {
    id: 'type',
    label: 'Type',
    minWidth: 120,
    align: 'center',
    sortable: false,
    render: ({ type }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '6px',
          marginLeft: '20px',
        }}
      >
        <EventCircle color={getColor(type)} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: '600', color: '#131613' }}
        >
          {type}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'center',
    sortable: false,
    render: ({ date }) => (
      <Box>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '600',
            color: '#131613',
            textAlign: 'center',
          }}
        >
          {moment(date).format('dddd')}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#828282',
            textAlign: 'center',
          }}
        >
          {moment(date).format('MMM D, HH:mm')}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'description',
    label: 'Explanation',
    minWidth: 100,
    align: 'center',
    sortable: false,
    render: ({ description }) => (
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '600',
          color: '#131613',
          width: '140px',
          marginLeft: '20px',
          textAlign: 'center',
        }}
      >
        {description}
      </Typography>
    ),
  },
];
