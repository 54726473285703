import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { colors } from 'shared/constants/theme';

export const SummaryWalletCard = (props: any): JSX.Element => {
  const { name, value, logo } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '16px',
        padding: '20px',
        background: colors.lightGreen,
        columnGap: '10px',
        alignItems: 'center',
        width: '260px',
      }}
    >
      <Avatar src={logo} />
      <Box>
        <Typography
          sx={{ color: colors.lightGrey, fontSize: '14px', fontWeight: '500' }}
        >
          {name}
        </Typography>
        <Typography
          sx={{ color: colors.lightGrey, fontSize: '20px', fontWeight: '500' }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
