import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { BitmainLogo } from 'components/Icons/Bitmain';
import { WhatsminerLogo } from 'components/Icons/Whatsminer';
import { CircleFlag } from 'react-circle-flags';
import { formatNumber } from 'shared/utils/formatter';
import countryCodes from 'shared/constants/country-codes.json';
import { colors } from 'shared/constants/theme';

const ContractCellContainer = (props: any) => {
  const { children, styles } = props;

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, ...styles }}
    >
      {children}
    </Box>
  );
};

export const ContractDetails = ({ name, amount }: any) => {
  return (
    <ContractCellContainer styles={{ alignItems: 'center' }}>
      <Typography
        sx={{ color: '#131616', fontSize: '18px', fontWeight: '600' }}
      >
        {name}
      </Typography>
      <Typography sx={{ color: colors.lightGreen, fontSize: '15px' }}>
        {formatNumber(amount)} $
      </Typography>
    </ContractCellContainer>
  );
};

export const MachineName = ({ machineType, machineModel }: any) => {
  const machineLogo = useMemo(() => {
    switch (machineType) {
      case 'Whatsminer':
        return <WhatsminerLogo />;
      case 'Bitmain':
        return <BitmainLogo />;
    }
  }, [machineType]);

  return (
    <ContractCellContainer>
      {machineLogo}

      <Typography sx={{ fontSize: '14px', color: '#959595' }}>
        {machineModel}
      </Typography>
    </ContractCellContainer>
  );
};

export const Miners = ({ numberOfMachines }: any) => {
  return (
    <ContractCellContainer>
      <Typography
        sx={{ color: '#1B2128', fontSize: '14px', fontWeight: '600' }}
      >
        {numberOfMachines}
      </Typography>
      <Typography sx={{ fontSize: '14px', color: '#959595' }}>
        MINERS
      </Typography>
    </ContractCellContainer>
  );
};

export const Hosting = ({ cost }: any) => {
  return (
    <ContractCellContainer>
      <Typography
        sx={{ color: '#1B2128', fontSize: '14px', fontWeight: '600' }}
      >
        {cost} CTS
      </Typography>
      <Typography sx={{ fontSize: '14px', color: '#959595' }}>
        PER KWh
      </Typography>
    </ContractCellContainer>
  );
};

export const Location = ({ country }: any) => {
  const location = useMemo(() => {
    switch (country) {
      case 'AE':
        return 'UAE';
      default:
        return countryCodes.countries.find((c) => c.alpha2 === country)?.alpha3;
    }
  }, [country]);

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '40px',
      }}
    >
      <ContractCellContainer>
        <Typography
          sx={{ color: '#1B2128', fontSize: '14px', fontWeight: '600' }}
        >
          {location}
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#959595' }}>
          HOSTED
        </Typography>
      </ContractCellContainer>
      <Box>
        <CircleFlag countryCode={country.toLowerCase()} height="48" />
      </Box>
    </Box>
  );
};
