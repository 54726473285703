import { Box } from '@mui/material';
import { ProfileDarkIcon } from 'components/Icons/DarkProfile';
import { SecurityIcon } from 'components/Icons/Security';
import { colors } from 'shared/constants/theme';

const menus = [
  { id: 1, name: 'profile', label: 'Profile details', icon: ProfileDarkIcon },
  { id: 2, name: 'security', label: 'Security', icon: SecurityIcon },
];

export const ProfileLeftSideMenu = (props: any) => {
  const { selectedMenu, setSelectedMenu } = props;

  return (
    <Box
      sx={{
        background: colors.lightGrey,
        padding: '20px',
        width: {
          xs: '100%',
          sm: '100%',
          md: '30%',
          lg: '30%',
          xl: '30%',
        },
        height: {
          xs: 'auto',
          sm: 'auto',
          md: '600px',
          lg: '600px',
          xl: '600px',
        },
        borderRadius: '10px',
        marginBottom: {
          xs: '20px',
          sm: '20px',
          md: '0',
          lg: '0',
          xl: '0',
        },
      }}
    >
      {menus.map((menu) => (
        <Box
          key={menu.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            cursor: 'pointer',
            backgroundColor:
              selectedMenu === menu.name ? colors.lightGreen : 'transparent',
            borderRadius: '10px',
            color: selectedMenu === menu.name ? 'white' : 'black',
          }}
          onClick={() => setSelectedMenu(menu.name)}
        >
          <Box
            sx={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
          >
            <menu.icon selected={selectedMenu === menu.name} />
          </Box>
          <Box>{menu.label}</Box>
        </Box>
      ))}
    </Box>
  );
};
