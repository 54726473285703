import { Skeleton } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { EventsTable } from 'components/Tables/EventsTable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import EventsService from 'shared/services/events.service';

export const Events = (props: any) => {
  const { currencyValue } = props;
  const [events, setEvents] = useState<any[]>([]);

  const { customerId } = useParams();

  const getCustomerEvents = async () => {
    const { events } = await EventsService.getEvents({
      customerId,
      currency: currencyValue,
    });
    return setEvents(events);
  };

  useEffect(() => {
    getCustomerEvents();
  }, [currencyValue]);

  return (
    <>
      <InfoCard
        subtitle="Events"
        showDivider={false}
        showTime={false}
        info="Events"
        styles={{ height: '690px' }}
        fullHeight
        hasOverflow
        content={
          !events ? (
            <Skeleton variant="rectangular" height={280} />
          ) : (
            <EventsTable events={events} />
          )
        }
      />
    </>
  );
};
