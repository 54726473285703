import { colors } from 'shared/constants/theme';

export const linePlugin = {
  id: 'dashedLine',
  afterDraw: function (chart: any) {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([10, 10]);

      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = colors.lightGreen;
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const addImagePlugin = (callback: (label: string) => string) => ({
  id: 'addImage',
  afterDatasetsDraw(chart: any) {
    const { ctx, data } = chart;
    ctx.save();

    data.labels?.forEach((label: any, index: number) => {
      const bar: any = chart.getDatasetMeta(0).data[index];

      const image = new Image();
      const src = callback(label);
      image.src = src;

      ctx.drawImage(
        image,
        bar.x - 10,
        bar.y - bar.height / 1.7,
        bar.height * 1.2,
        bar.height * 1.2,
      );

      const borderWidth = 2;
      const cornerRadius = 25;
      ctx.strokeStyle = '#E0E0E0';
      ctx.lineWidth = borderWidth;

      ctx.beginPath();
      ctx.moveTo(bar.x - 10 + cornerRadius, bar.y - bar.height / 1.7);
      ctx.arcTo(
        bar.x - 10,
        bar.y - bar.height / 1.7,
        bar.x - 10,
        bar.y - bar.height / 1.7 + bar.height * 1.2,
        cornerRadius,
      );
      ctx.arcTo(
        bar.x - 10,
        bar.y - bar.height / 1.7 + bar.height * 1.2,
        bar.x - 10 + bar.height * 1.2,
        bar.y - bar.height / 1.7 + bar.height * 1.2,
        cornerRadius,
      );
      ctx.arcTo(
        bar.x - 10 + bar.height * 1.2,
        bar.y - bar.height / 1.7 + bar.height * 1.2,
        bar.x - 10 + bar.height * 1.2,
        bar.y - bar.height / 1.7,
        cornerRadius,
      );
      ctx.arcTo(
        bar.x - 10 + bar.height * 1.2,
        bar.y - bar.height / 1.7,
        bar.x - 10,
        bar.y - bar.height / 1.7,
        cornerRadius,
      );
      ctx.closePath();
      ctx.stroke();
    });

    ctx.restore();
  },
});
