import { Box } from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import moment from 'moment';

export const columns: ColumnsType[] = [
  {
    label: 'Date',
    minWidth: 100,
    align: 'center',
    render: ({ timestamp }) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {timestamp
            ? moment.unix(timestamp).format('YYYY-MM-DD')
            : 'Date Undefined'}
        </Box>
      );
    },
  },
  {
    id: 'transaction',
    label: 'BTC Transaction',
    align: 'center',
    minWidth: 100,
    render: ({ amount }) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {amount} BTC
        </Box>
      );
    },
  },
];
