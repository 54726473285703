import { Box, Avatar, Typography } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const CoinAvatarWithSymbol = (token: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '12px',
      }}
    >
      <Avatar src={token?.logoUrl} sx={{ width: '30px', height: '30px' }} />
      <Typography
        sx={{ fontSize: '16px', color: colors.darkBlue, fontWeight: '500' }}
      >
        {token?.optimizedSymbol}
      </Typography>
    </Box>
  );
};
