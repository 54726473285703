import { Box } from '@mui/material';
import { Layout } from 'components/Layout';
import AffiliateSubAccountTable from 'components/Tables/AffiliateSubAccountTable';

export const TeamMembers = () => {
  return (
    <Layout backgroundHeight="600px">
      <Box sx={{ minHeight: '100vh' }}>
        <AffiliateSubAccountTable />
      </Box>
    </Layout>
  );
};
