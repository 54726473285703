import { Box } from '@mui/material';
import { formatCurrency } from 'shared/utils/formatter';
import { CoinAvatarWithSymbol } from '../ProtocolTable/components';

const getFirstColumn = (table: any) => {
  switch (table) {
    case 'supplied':
      return {
        id: 'supplied',
        label: 'Supplied',
      };
    case 'borrowed':
      return {
        id: 'borrowed',
        label: 'Borrowed',
      };
    case 'rewards':
      return {
        id: 'rewards',
        label: 'Rewards',
      };
    default:
      return {
        id: 'supplied',
        label: 'Supplied',
      };
  }
};

export const columns = (table: any) => {
  return [
    {
      ...getFirstColumn(table),
      minWidth: 180,
      width: 500,
      align: 'left',
      sortable: false,
      render: (token: any) => CoinAvatarWithSymbol(token),
    },
    {
      id: 'balance',
      label: 'Balance',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: (token: any) => (
        <Box>
          {token?.amount.toFixed(4)} {token?.optimizedSymbol}
        </Box>
      ),
    },
    {
      id: 'value',
      label: 'USD Value',
      minWidth: 120,
      align: 'right',
      sortable: false,
      render: (token: any) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '6px',
            width: '100%',
          }}
        >
          {formatCurrency(token?.price * token?.amount)}
        </Box>
      ),
    },
  ];
};
