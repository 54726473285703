import { Avatar, Box, Typography } from '@mui/material';
import { RedesignedTable } from '../RedesignedTable';
import { columns } from './columns';
import WalletIcon from 'assets/logos/walletIcon.svg';
import { colors } from 'shared/constants/theme';

export const CoinsTable = (props: any) => {
  const { coins, summaryCost } = props;

  return (
    <Box sx={{ marginTop: '40px' }}>
      <Box
        sx={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <Avatar src={WalletIcon} sx={{ width: '40px', height: '40px' }} />
          <Typography
            sx={{ fontSize: '28px', color: colors.darkBlue, fontWeight: '500' }}
          >
            Wallet
          </Typography>
        </Box>
        <Typography
          sx={{ fontSize: '28px', color: colors.darkBlue, fontWeight: '500' }}
        >
          {summaryCost}
        </Typography>
      </Box>
      <RedesignedTable items={coins} columns={columns} paginated={false} />
    </Box>
  );
};
