import { styled } from '@mui/material/styles';
import { TableCell, TableRow, tableCellClasses } from '@mui/material';

export const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F2F2F2 !important',
    color: '#828282 !important',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    margin: '20px 0 !important',
    color: '#131613 !important',
  },
}));
