import { Box, Typography } from '@mui/material';
import { CheckMark } from 'components/CheckMark';
import { modalTitleStyles } from 'components/Forms/AdminSavedSimulationForm/styles';

export const SaveSuccessModal = (): JSX.Element => {
  return (
    <Box className="container">
      <Box
        className="user-content"
        sx={{
          padding: '50px 100px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CheckMark />
        <Typography sx={modalTitleStyles}>Changes Saved</Typography>
      </Box>
    </Box>
  );
};
