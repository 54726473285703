import { colors } from 'shared/constants/theme';

export const HearstIconBackground = () => (
  <svg
    width="149"
    height="148"
    viewBox="0 0 149 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3275_20825)">
      <rect x="0.5" width="148" height="148" rx="20" fill={colors.lightGreen} />
      <g opacity="0.24" clip-path="url(#clip1_3275_20825)">
        <path
          d="M-14.6287 5.77557C-15.0166 5.77557 -15.332 6.08954 -15.332 6.47568C-15.332 6.86183 -15.0166 7.17579 -14.6287 7.17579C-14.306 7.17579 -14.0341 6.95566 -13.9544 6.66334H-3.82121L4.39044 -1.51066H34.6014C34.6847 -1.21474 34.953 -0.998211 35.2757 -0.998211C35.6636 -0.998211 35.979 -1.31218 35.979 -1.69832C35.979 -2.08447 35.6636 -2.39844 35.2757 -2.39844C34.953 -2.39844 34.6811 -2.1783 34.6014 -1.88598H4.23455L-3.97711 6.28802H-13.9544C-14.0377 5.9921 -14.306 5.77557 -14.6287 5.77557Z"
          fill="url(#paint0_linear_3275_20825)"
        />
        <path
          d="M7.3635 3.43945C6.97557 3.43945 6.66016 3.75342 6.66016 4.13957C6.66016 4.52571 6.97557 4.83968 7.3635 4.83968C7.68616 4.83968 7.95807 4.61954 8.03783 4.32723H42.9401L48.3638 9.72604H85.3289C85.4123 10.022 85.6806 10.2385 86.0032 10.2385C86.3912 10.2385 86.7066 9.92452 86.7066 9.53838C86.7066 9.15223 86.3912 8.83827 86.0032 8.83827C85.6806 8.83827 85.4087 9.0548 85.3289 9.35072H48.5197L43.096 3.95191H8.03783C7.95444 3.65598 7.68616 3.43945 7.3635 3.43945Z"
          fill="url(#paint1_linear_3275_20825)"
        />
        <path
          d="M53.1037 7.75653C53.4264 7.75653 53.6983 7.53639 53.7781 7.24407H91.1819L99.0999 -0.637618H124.913C124.997 -0.341694 125.265 -0.125164 125.587 -0.125164C125.975 -0.125164 126.291 -0.439132 126.291 -0.825277C126.291 -1.21142 125.975 -1.52539 125.587 -1.52539C125.265 -1.52539 124.993 -1.30525 124.913 -1.01294H98.944L91.026 6.86875H53.7781C53.6947 6.57283 53.4264 6.3563 53.1037 6.3563C52.7158 6.3563 52.4004 6.67027 52.4004 7.05641C52.4004 7.44617 52.7158 7.75653 53.1037 7.75653Z"
          fill="url(#paint2_linear_3275_20825)"
        />
        <path
          d="M96.5984 12.7077H71.0788C70.9955 12.4118 70.7272 12.1952 70.4045 12.1952C70.0166 12.1952 69.7012 12.5092 69.7012 12.8953C69.7012 13.2815 70.0166 13.5954 70.4045 13.5954C70.7272 13.5954 70.9991 13.3753 71.0788 13.083H96.7543L105.698 4.18001H133.545L139.19 9.79896H168.604C168.687 10.0949 168.955 10.3114 169.278 10.3114C169.666 10.3114 169.981 9.99744 169.981 9.6113C169.981 9.22515 169.666 8.91119 169.278 8.91119C168.955 8.91119 168.683 9.12771 168.604 9.42364H139.346L133.701 3.80469H105.542L96.5984 12.7077Z"
          fill="url(#paint3_linear_3275_20825)"
        />
        <path
          d="M34.6877 66.4249C35.0756 66.4249 35.391 66.111 35.391 65.7248C35.391 65.3387 35.0756 65.0247 34.6877 65.0247C34.365 65.0247 34.0931 65.2412 34.0134 65.5371H-2.06355L-6.31259 61.3076C-6.16032 61.0405 -6.19657 60.6977 -6.42498 60.4704C-6.70051 60.1961 -7.14644 60.1961 -7.41835 60.4704C-7.69388 60.7446 -7.69388 61.1885 -7.41835 61.4592C-7.18995 61.6865 -6.84553 61.7226 -6.57724 61.571L-2.21945 65.9089H34.0134C34.0967 66.2084 34.365 66.4249 34.6877 66.4249Z"
          fill="url(#paint4_linear_3275_20825)"
        />
        <path
          d="M-34.8631 57.0852C-34.5404 57.0852 -34.2685 56.8651 -34.1887 56.5728H-9.6879L2.04044 44.8982H28.4301L36.4533 36.9119C36.7215 37.0634 37.066 37.0274 37.2944 36.8C37.5699 36.5257 37.5699 36.0818 37.2944 35.8112C37.0188 35.5369 36.5729 35.5369 36.301 35.8112C36.0726 36.0385 36.0363 36.3814 36.1886 36.6484L28.2742 44.5265H1.88817L-9.84017 56.2011H-34.1887C-34.2721 55.9051 -34.5404 55.6886 -34.8631 55.6886C-35.251 55.6886 -35.5664 56.0026 -35.5664 56.3887C-35.5664 56.7713 -35.251 57.0852 -34.8631 57.0852Z"
          fill="url(#paint5_linear_3275_20825)"
        />
        <path
          d="M30.5106 60.5428C30.7861 60.817 31.232 60.817 31.5039 60.5428C31.7795 60.2685 31.7795 59.8246 31.5039 59.554C31.2755 59.3266 30.9311 59.2905 30.6628 59.4421L27.5522 56.3457H3.79097L0.752835 59.3699C0.484551 59.2183 0.140133 59.2544 -0.0882709 59.4818C-0.363806 59.756 -0.363806 60.1999 -0.0882709 60.4706C0.187264 60.7449 0.633197 60.7449 0.905106 60.4706C1.13351 60.2432 1.16976 59.9004 1.01749 59.6333L3.94686 56.7174H27.3963L30.3982 59.7055C30.2495 59.9726 30.2822 60.3154 30.5106 60.5428Z"
          fill="url(#paint6_linear_3275_20825)"
        />
        <path
          d="M52.8434 17.3051L41.9924 6.50391H6.35783L2.54385 10.2968H-34.7005C-34.7838 10.0009 -35.0521 9.78433 -35.3748 9.78433C-35.7627 9.78433 -36.0781 10.0983 -36.0781 10.4844C-36.0781 10.8706 -35.7627 11.1846 -35.3748 11.1846C-35.0521 11.1846 -34.7802 10.9644 -34.7005 10.6721H2.70337L6.51373 6.87923H41.8402L52.6911 17.6805H100.348L108.81 9.25745C109.078 9.40902 109.422 9.37293 109.651 9.14557C109.926 8.8713 109.926 8.42741 109.651 8.15675C109.375 7.88248 108.929 7.88248 108.657 8.15675C108.429 8.38411 108.393 8.72695 108.545 8.994L100.192 17.3088H52.8434V17.3051Z"
          fill="url(#paint7_linear_3275_20825)"
        />
        <path
          d="M-1.62224 18.3343C-1.89778 18.06 -2.34371 18.06 -2.61561 18.3343C-2.89115 18.6086 -2.89115 19.0525 -2.61561 19.3231C-2.38721 19.5505 -2.04279 19.5866 -1.77451 19.435L0.603788 21.8024H38.033L44.0259 15.837H46.473C46.5564 16.1329 46.8247 16.3494 47.1474 16.3494C47.5353 16.3494 47.8507 16.0355 47.8507 15.6493C47.8507 15.2632 47.5353 14.9492 47.1474 14.9492C46.8247 14.9492 46.5528 15.1657 46.473 15.4617H43.87L37.8771 21.4271H0.759683L-1.50985 19.1679C-1.35758 18.9045 -1.39384 18.5617 -1.62224 18.3343Z"
          fill="url(#paint8_linear_3275_20825)"
        />
        <path
          d="M2.40878 16.5035L-0.230545 13.8763H-25.761C-25.8444 13.5804 -26.1127 13.3639 -26.4353 13.3639C-26.8233 13.3639 -27.1387 13.6778 -27.1387 14.064C-27.1387 14.4501 -26.8233 14.7641 -26.4353 14.7641C-26.1127 14.7641 -25.8408 14.5439 -25.761 14.2516H-0.386442L2.25289 16.8789H34.0771L37.3364 13.6345C37.6047 13.7861 37.9491 13.75 38.1775 13.5226C38.4531 13.2484 38.4531 12.8045 38.1775 12.5338C37.902 12.2596 37.4561 12.2596 37.1842 12.5338C36.9557 12.7612 36.9195 13.104 37.0718 13.3711L33.9249 16.5035H2.40878Z"
          fill="url(#paint9_linear_3275_20825)"
        />
        <path
          d="M-9.64958 19.5358C-9.92512 19.2615 -10.371 19.2615 -10.643 19.5358C-10.9185 19.8101 -10.9185 20.2539 -10.643 20.5246C-10.4146 20.752 -10.0701 20.788 -9.80185 20.6365L-0.274154 30.1205H42.4337L50.2538 22.3362C50.5221 22.4878 50.8665 22.4517 51.0949 22.2244C51.3705 21.9501 51.3705 21.5062 51.0949 21.2355C50.8194 20.9613 50.3735 20.9613 50.1016 21.2355C49.8731 21.4629 49.8369 21.8057 49.9892 22.0728L42.2778 29.7488H-0.121885L-9.53719 20.3766C-9.38855 20.1096 -9.42118 19.7631 -9.64958 19.5358Z"
          fill="url(#paint10_linear_3275_20825)"
        />
        <path
          d="M131.379 7.39844C131.056 7.39844 130.784 7.61497 130.704 7.91089H115.677L103.858 19.6757H48.1055L41.1772 26.5722H1.30806L-7.4873 17.8171H-32.5755C-32.6588 17.5212 -32.9271 17.3047 -33.2498 17.3047C-33.6377 17.3047 -33.9531 17.6187 -33.9531 18.0048C-33.9531 18.3909 -33.6377 18.7049 -33.2498 18.7049C-32.9271 18.7049 -32.6552 18.4848 -32.5755 18.1925H-7.64319L1.15217 26.9475H41.3331L48.2613 20.051H104.01L115.829 8.28621H130.701C130.784 8.58213 131.052 8.79866 131.375 8.79866C131.763 8.79866 132.078 8.4847 132.078 8.09855C132.082 7.71241 131.766 7.39844 131.379 7.39844Z"
          fill="url(#paint11_linear_3275_20825)"
        />
        <path
          d="M32.1336 45.5868C31.8581 45.3126 31.4122 45.3126 31.1402 45.5868C30.8647 45.8611 30.8647 46.305 31.1402 46.5756C31.3686 46.803 31.7131 46.8391 31.9814 46.6875L39.1996 53.8727H73.8807L86.6351 41.1768H112.887C112.97 41.4728 113.239 41.6893 113.561 41.6893C113.949 41.6893 114.265 41.3753 114.265 40.9892C114.265 40.603 113.949 40.2891 113.561 40.2891C113.239 40.2891 112.967 40.5092 112.887 40.8015H86.4792L73.7248 53.4974H39.3519L32.246 46.4241C32.3983 46.157 32.362 45.8142 32.1336 45.5868Z"
          fill="url(#paint12_linear_3275_20825)"
        />
        <path
          d="M46.3024 33.8405L35.8792 44.2159L41.6183 49.9286H72.5616L84.8374 37.7091C85.1057 37.8607 85.4501 37.8246 85.6785 37.5973C85.954 37.323 85.954 36.8791 85.6785 36.6084C85.403 36.3342 84.957 36.3342 84.6851 36.6084C84.4567 36.8358 84.4205 37.1786 84.5727 37.4457L72.4093 49.5569H41.7742L36.4086 44.2159L47.2052 33.4688H35.5711L30.2235 38.7918C29.9553 38.6402 29.6108 38.6763 29.3824 38.9036C29.1069 39.1779 29.1069 39.6218 29.3824 39.8925C29.658 40.1667 30.1039 40.1667 30.3758 39.8925C30.6042 39.6651 30.6405 39.3223 30.4882 39.0552L35.727 33.8405H46.3024Z"
          fill="url(#paint13_linear_3275_20825)"
        />
        <path
          d="M20.9636 37.8928C21.0469 38.1887 21.3152 38.4052 21.6379 38.4052C22.0258 38.4052 22.3412 38.0913 22.3412 37.7051C22.3412 37.319 22.0258 37.005 21.6379 37.005C21.3152 37.005 21.0469 37.2215 20.9636 37.5175H1.30634L-12.9888 23.2878H-30.3801C-30.4635 22.9919 -30.7318 22.7754 -31.0545 22.7754C-31.4424 22.7754 -31.7578 23.0894 -31.7578 23.4755C-31.7578 23.8616 -31.4424 24.1756 -31.0545 24.1756C-30.7318 24.1756 -30.4599 23.9555 -30.3801 23.6632H-13.1447L1.15044 37.8928H20.9636Z"
          fill="url(#paint14_linear_3275_20825)"
        />
        <path
          d="M-34.1165 51.5312C-34.1999 51.2352 -34.4681 51.0187 -34.7908 51.0187C-35.1787 51.0187 -35.4941 51.3327 -35.4941 51.7188C-35.4941 52.105 -35.1787 52.4189 -34.7908 52.4189C-34.4681 52.4189 -34.1962 52.1988 -34.1165 51.9065H-8.80716L-0.599129 43.7361C-0.330845 43.8877 0.0135727 43.8516 0.241978 43.6242C0.517513 43.3499 0.517513 42.9061 0.241978 42.6354C-0.0335579 42.3611 -0.479488 42.3611 -0.751396 42.6354C-0.979801 42.8627 -1.01606 43.2056 -0.863789 43.4726L-8.95943 51.5312H-34.1165Z"
          fill="url(#paint15_linear_3275_20825)"
        />
        <path
          d="M53.6046 24.0574L48.9169 28.7236C48.6486 28.572 48.3042 28.6081 48.0758 28.8355C47.8003 29.1097 47.8003 29.5536 48.0758 29.8243C48.3513 30.0986 48.7973 30.0986 49.0692 29.8243C49.2976 29.5969 49.3338 29.2541 49.1816 28.987L53.7605 24.4291H107.312L112.054 19.7087C112.322 19.8603 112.667 19.8242 112.895 19.5969C113.171 19.3226 113.171 18.8787 112.895 18.608C112.62 18.3338 112.174 18.3338 111.902 18.608C111.674 18.8354 111.637 19.1782 111.79 19.4453L107.156 24.0574H53.6046Z"
          fill="url(#paint16_linear_3275_20825)"
        />
        <path
          d="M103.668 32.7688C104.056 32.7688 104.371 32.4549 104.371 32.0687C104.371 31.6826 104.056 31.3686 103.668 31.3686C103.345 31.3686 103.073 31.5887 102.993 31.881H79.2794L72.8478 38.2831H46.2189L54.0498 30.488C54.3181 30.6396 54.6626 30.6035 54.891 30.3762C55.1665 30.1019 55.1665 29.658 54.891 29.3873C54.6154 29.1131 54.1695 29.1131 53.8976 29.3873C53.6692 29.6147 53.6329 29.9575 53.7852 30.2246L45.3125 38.6584H73.0001L79.4317 32.2564H102.99C103.073 32.5487 103.345 32.7688 103.668 32.7688Z"
          fill="url(#paint17_linear_3275_20825)"
        />
        <path
          d="M44.4153 45.1802L42.5845 43.3577C42.7368 43.0906 42.7005 42.7478 42.4721 42.5204C42.1966 42.2462 41.7506 42.2462 41.4787 42.5204C41.2068 42.7947 41.2032 43.2386 41.4787 43.5093C41.7071 43.7366 42.0516 43.7727 42.3198 43.6211L44.2595 45.5519H71.9035L79.3394 38.1501C79.6076 38.3017 79.9521 38.2656 80.1805 38.0383C80.456 37.764 80.456 37.3201 80.1805 37.0495C79.9049 36.7752 79.459 36.7752 79.1871 37.0495C78.9587 37.2768 78.9224 37.6196 79.0747 37.8867L71.7477 45.1802H44.4153Z"
          fill="url(#paint18_linear_3275_20825)"
        />
        <path
          d="M127.129 13.4005H137.302C137.385 13.6964 137.653 13.9129 137.976 13.9129C138.364 13.9129 138.679 13.599 138.679 13.2128C138.679 12.8267 138.364 12.5127 137.976 12.5127C137.653 12.5127 137.385 12.7328 137.302 13.0251H126.977L111.336 28.5937H75.7633L70.4303 33.9022H55.4535L60.4276 28.9509C60.6959 29.1025 61.0403 29.0664 61.2687 28.8391C61.5443 28.5648 61.5443 28.1209 61.2687 27.8502C60.9932 27.576 60.5473 27.576 60.2754 27.8502C60.047 28.0776 60.0107 28.4204 60.163 28.6875L54.5508 34.274H70.5825L75.9156 28.9654H111.489L127.129 13.4005Z"
          fill="url(#paint19_linear_3275_20825)"
        />
        <path
          d="M-35.2302 47.1607C-34.9076 47.1607 -34.6356 46.9406 -34.5559 46.6482H-12.7669L-7.04955 40.9571H6.66917C6.75256 41.253 7.02084 41.4696 7.34351 41.4696C7.73143 41.4696 8.04684 41.1556 8.04684 40.7695C8.04684 40.3833 7.73143 40.0693 7.34351 40.0693C7.02084 40.0693 6.75256 40.2859 6.66917 40.5818H-7.20545L-12.9228 46.2729H-34.5559C-34.6393 45.977 -34.9076 45.7605 -35.2302 45.7605C-35.6181 45.7605 -35.9336 46.0744 -35.9336 46.4606C-35.9372 46.8503 -35.6218 47.1607 -35.2302 47.1607Z"
          fill="url(#paint20_linear_3275_20825)"
        />
        <path
          d="M114.209 49.8243C114.292 50.1202 114.561 50.3368 114.883 50.3368C115.271 50.3368 115.587 50.0228 115.587 49.6366C115.587 49.2505 115.271 48.9365 114.883 48.9365C114.561 48.9365 114.289 49.1531 114.209 49.449H86.5904L78.6289 57.374H93.0945C93.1779 57.6699 93.4461 57.8864 93.7688 57.8864C94.1567 57.8864 94.4721 57.5725 94.4721 57.1863C94.4721 56.8002 94.1567 56.4862 93.7688 56.4862C93.4461 56.4862 93.1742 56.7027 93.0945 56.9987H79.5316L86.7463 49.8207H114.209V49.8243Z"
          fill="url(#paint21_linear_3275_20825)"
        />
        <path
          d="M127.055 51.3543H149.533L158.274 42.6534H172.268C172.352 42.9493 172.62 43.1659 172.943 43.1659C173.331 43.1659 173.646 42.8519 173.646 42.4657C173.646 42.0796 173.331 41.7656 172.943 41.7656C172.62 41.7656 172.352 41.9822 172.268 42.2781H158.122L149.381 50.979H127.215L120.069 43.866H88.4659L82.5673 49.7375C82.299 49.586 81.9546 49.6221 81.7262 49.8494C81.4506 50.1237 81.4506 50.5676 81.7262 50.8382C82.0017 51.1125 82.4476 51.1125 82.7196 50.8382C82.948 50.6109 82.9842 50.268 82.832 50.001L88.6182 44.2413H119.913L127.055 51.3543Z"
          fill="url(#paint22_linear_3275_20825)"
        />
        <path
          d="M151.677 24.2192L144.694 17.2686H128.155L117.532 27.846C117.264 27.6945 116.919 27.7306 116.691 27.9579C116.415 28.2322 116.415 28.6761 116.691 28.9467C116.967 29.221 117.412 29.221 117.684 28.9467C117.913 28.7194 117.949 28.3765 117.797 28.1095L128.314 17.6403H144.542L151.524 24.5909H179.306C179.39 24.8868 179.658 25.1033 179.981 25.1033C180.369 25.1033 180.684 24.7894 180.684 24.4032C180.684 24.0171 180.369 23.7031 179.981 23.7031C179.658 23.7031 179.386 23.9196 179.306 24.2156H151.677V24.2192Z"
          fill="url(#paint23_linear_3275_20825)"
        />
        <path
          d="M181.3 49.9219C180.978 49.9219 180.706 50.1384 180.626 50.4343H161.694L155.976 56.1255H121.165L118.747 53.7184H93.8796C93.7962 53.4224 93.528 53.2059 93.2053 53.2059C92.8174 53.2059 92.502 53.5199 92.502 53.906C92.502 54.2922 92.8174 54.6061 93.2053 54.6061C93.528 54.6061 93.7999 54.386 93.8796 54.0937H118.594L121.013 56.5008H156.136L161.853 50.8096H180.63C180.713 51.1056 180.981 51.3221 181.304 51.3221C181.692 51.3221 182.007 51.0081 182.007 50.622C182.007 50.2358 181.688 49.9219 181.3 49.9219Z"
          fill="url(#paint24_linear_3275_20825)"
        />
        <path
          d="M167.27 29.1676C167.27 28.7815 166.954 28.4675 166.566 28.4675C166.244 28.4675 165.972 28.6876 165.892 28.98H147.337L142.497 24.1658H128.405C128.321 23.8698 128.053 23.6533 127.73 23.6533C127.342 23.6533 127.027 23.9673 127.027 24.3534C127.027 24.7396 127.342 25.0535 127.73 25.0535C128.053 25.0535 128.325 24.8334 128.405 24.5411H140.677C140.644 24.5952 140.619 24.653 140.601 24.7143H128.597L117.271 35.9883H92.3386C92.2552 35.6924 91.9869 35.4759 91.6643 35.4759C91.2764 35.4759 90.9609 35.7898 90.9609 36.176C90.9609 36.5621 91.2764 36.8761 91.6643 36.8761C91.9869 36.8761 92.2589 36.6559 92.3386 36.3636H117.427L128.753 25.0896H140.601C140.684 25.3856 140.952 25.6021 141.275 25.6021C141.663 25.6021 141.978 25.2881 141.978 24.902C141.978 24.7684 141.938 24.6457 141.873 24.5411H142.341L147.177 29.3553H165.888C165.972 29.6512 166.24 29.8677 166.563 29.8677C166.954 29.8677 167.27 29.5538 167.27 29.1676Z"
          fill="url(#paint25_linear_3275_20825)"
        />
        <path
          d="M186.796 35.5832C186.474 35.5832 186.205 35.8033 186.122 36.0956H156.306L145.861 46.4927H128.644L121.574 39.4554L129.924 31.1443C130.192 31.2959 130.536 31.2598 130.765 31.0324C131.04 30.7581 131.04 30.3143 130.765 30.0436C130.489 29.7693 130.043 29.7693 129.771 30.0436C129.543 30.271 129.507 30.6138 129.659 30.8808L121.045 39.4554L128.488 46.8644H146.017L156.462 36.4673H186.125C186.209 36.7632 186.477 36.9798 186.8 36.9798C187.188 36.9798 187.503 36.6658 187.503 36.2797C187.5 35.8935 187.188 35.5832 186.796 35.5832Z"
          fill="url(#paint26_linear_3275_20825)"
        />
        <path
          d="M165.578 33.8084C165.966 33.8084 166.281 33.4945 166.281 33.1083C166.281 32.7222 165.966 32.4082 165.578 32.4082C165.255 32.4082 164.983 32.6283 164.903 32.9207H136.846L131.72 38.0235C131.451 37.872 131.107 37.9081 130.879 38.1354C130.603 38.4097 130.603 38.8536 130.879 39.1242C131.154 39.3985 131.6 39.3985 131.872 39.1242C132.1 38.8969 132.137 38.554 131.984 38.287L137.002 33.2924H164.903C164.983 33.5919 165.255 33.8084 165.578 33.8084Z"
          fill="url(#paint27_linear_3275_20825)"
        />
        <path
          d="M143.158 42.4872L146.417 39.2429C146.685 39.3945 147.03 39.3584 147.258 39.131C147.533 38.8568 147.533 38.4129 147.258 38.1422C146.982 37.8679 146.536 37.8679 146.265 38.1422C146.036 38.3696 146 38.7124 146.152 38.9795L143.005 42.1119H133.637L138.281 37.489C138.55 37.6406 138.894 37.6045 139.122 37.3771C139.398 37.1029 139.398 36.659 139.122 36.3883C138.847 36.114 138.401 36.114 138.129 36.3883C137.901 36.6157 137.864 36.9585 138.017 37.2256L132.734 42.4836H143.158V42.4872Z"
          fill="url(#paint28_linear_3275_20825)"
        />
      </g>
      <g opacity="0.24" clip-path="url(#clip2_3275_20825)">
        <path
          d="M141.637 81.6934C142.024 81.6934 142.34 82.0073 142.34 82.3935C142.34 82.7796 142.024 83.0936 141.637 83.0936C141.314 83.0936 141.042 82.8734 140.962 82.5811H106.06L100.636 87.9799H63.6711C63.5877 88.2759 63.3194 88.4924 62.9968 88.4924C62.6088 88.4924 62.2934 88.1784 62.2934 87.7923C62.2934 87.4061 62.6088 87.0922 62.9968 87.0922C63.3194 87.0922 63.5913 87.3087 63.6711 87.6046H100.48L105.904 82.2058H140.962C141.046 81.9099 141.314 81.6934 141.637 81.6934Z"
          fill="url(#paint29_linear_3275_20825)"
        />
        <path
          d="M95.8963 86.0104C95.5736 86.0104 95.3017 85.7903 95.2219 85.498H57.8181L49.9001 77.6163H24.0869C24.0035 77.9122 23.7352 78.1287 23.4125 78.1287C23.0246 78.1287 22.7092 77.8148 22.7092 77.4286C22.7092 77.0425 23.0246 76.7285 23.4125 76.7285C23.7352 76.7285 24.0071 76.9487 24.0869 77.241H50.056L57.974 85.1227H95.2219C95.3053 84.8267 95.5736 84.6102 95.8963 84.6102C96.2842 84.6102 96.5996 84.9242 96.5996 85.3103C96.5996 85.7001 96.2842 86.0104 95.8963 86.0104Z"
          fill="url(#paint30_linear_3275_20825)"
        />
        <path
          d="M52.4016 90.9616H77.9212C78.0045 90.6657 78.2728 90.4491 78.5955 90.4491C78.9834 90.4491 79.2988 90.7631 79.2988 91.1492C79.2988 91.5354 78.9834 91.8494 78.5955 91.8494C78.2728 91.8494 78.0009 91.6292 77.9212 91.3369H52.2457L43.3017 82.4339H15.4546L9.80972 88.0529H-19.6036C-19.687 88.3488 -19.9553 88.5653 -20.2779 88.5653C-20.6659 88.5653 -20.9813 88.2513 -20.9813 87.8652C-20.9813 87.4791 -20.6659 87.1651 -20.2779 87.1651C-19.9553 87.1651 -19.6834 87.3816 -19.6036 87.6775H9.65383L15.2987 82.0586H43.4576L52.4016 90.9616Z"
          fill="url(#paint31_linear_3275_20825)"
        />
        <path
          d="M114.312 144.679C113.924 144.679 113.609 144.365 113.609 143.979C113.609 143.593 113.924 143.279 114.312 143.279C114.635 143.279 114.907 143.495 114.987 143.791H151.064L155.313 139.562C155.16 139.294 155.197 138.952 155.425 138.724C155.701 138.45 156.146 138.45 156.418 138.724C156.694 138.999 156.694 139.442 156.418 139.713C156.19 139.94 155.846 139.977 155.577 139.825L151.219 144.163H114.987C114.903 144.462 114.635 144.679 114.312 144.679Z"
          fill="url(#paint32_linear_3275_20825)"
        />
        <path
          d="M37.779 149.861C37.3911 149.861 37.0757 149.547 37.0757 149.161C37.0757 148.775 37.3911 148.461 37.779 148.461C38.1017 148.461 38.3736 148.681 38.4534 148.973H75.6288L83.6193 141.02H101.221L107.526 147.295H117.8C117.883 146.999 118.152 146.783 118.474 146.783C118.862 146.783 119.178 147.097 119.178 147.483C119.178 147.869 118.862 148.183 118.474 148.183C118.152 148.183 117.88 147.963 117.8 147.671H107.373L101.069 141.395H83.7752L75.7847 149.349H38.4534C38.37 149.641 38.1017 149.861 37.779 149.861Z"
          fill="url(#paint33_linear_3275_20825)"
        />
        <path
          d="M-14.7069 141.088C-14.3843 141.088 -14.1124 141.304 -14.0326 141.6H16.1892L26.3079 151.669H43.9094C43.9928 151.373 44.2611 151.157 44.5838 151.157C44.9717 151.157 45.2871 151.47 45.2871 151.857C45.2871 152.243 44.9717 152.557 44.5838 152.557C44.2611 152.557 43.9892 152.337 43.9094 152.044H26.152L16.0333 141.976H-14.0326C-14.116 142.272 -14.3843 142.488 -14.7069 142.488C-15.0948 142.488 -15.4103 142.174 -15.4103 141.788C-15.4103 141.402 -15.0985 141.088 -14.7069 141.088Z"
          fill="url(#paint34_linear_3275_20825)"
        />
        <path
          d="M183.863 135.339C183.54 135.339 183.268 135.119 183.189 134.827H158.688L146.96 123.152H120.57L112.547 115.166C112.278 115.317 111.934 115.281 111.706 115.054C111.43 114.78 111.43 114.336 111.706 114.065C111.981 113.791 112.427 113.791 112.699 114.065C112.927 114.292 112.964 114.635 112.811 114.902L120.726 122.78H147.112L158.84 134.455H183.189C183.272 134.159 183.54 133.943 183.863 133.943C184.251 133.943 184.566 134.256 184.566 134.643C184.566 135.025 184.251 135.339 183.863 135.339Z"
          fill="url(#paint35_linear_3275_20825)"
        />
        <path
          d="M83.4815 136.869L77.6155 142.708H32.7542L26.957 148.475L18.0819 139.641H-13.4487C-13.5321 139.936 -13.8003 140.153 -14.123 140.153C-14.5109 140.153 -14.8263 139.839 -14.8263 139.453C-14.8263 139.067 -14.5109 138.753 -14.123 138.753C-13.8003 138.753 -13.5284 138.969 -13.4487 139.265H18.2378L26.957 147.944L32.5982 142.329H77.4633L83.3293 136.49H110.6L120.864 126.273H145.941L152.351 132.661C152.619 132.509 152.963 132.546 153.192 132.773C153.467 133.047 153.467 133.491 153.192 133.762C152.916 134.036 152.47 134.036 152.198 133.762C151.97 133.534 151.934 133.192 152.086 132.925L145.785 126.652H121.016L110.752 136.869H83.4815Z"
          fill="url(#paint36_linear_3275_20825)"
        />
        <path
          d="M118.489 138.797C118.214 139.071 117.768 139.071 117.496 138.797C117.221 138.522 117.221 138.079 117.496 137.808C117.724 137.581 118.069 137.544 118.337 137.696L121.448 134.6H145.209L148.247 137.624C148.515 137.472 148.86 137.508 149.088 137.736C149.364 138.01 149.364 138.454 149.088 138.725C148.813 138.999 148.367 138.999 148.095 138.725C147.866 138.497 147.83 138.154 147.983 137.887L145.053 134.971H121.604L118.602 137.959C118.75 138.226 118.718 138.569 118.489 138.797Z"
          fill="url(#paint37_linear_3275_20825)"
        />
        <path
          d="M96.1566 95.559L107.008 84.7578H142.642L146.456 88.5507H183.7C183.784 88.2548 184.052 88.0382 184.375 88.0382C184.763 88.0382 185.078 88.3522 185.078 88.7384C185.078 89.1245 184.763 89.4385 184.375 89.4385C184.052 89.4385 183.78 89.2183 183.7 88.926H146.297L142.486 85.1331H107.16L96.3089 95.9344H48.6522L40.1904 87.5114C39.9221 87.6629 39.5777 87.6268 39.3493 87.3995C39.0738 87.1252 39.0738 86.6813 39.3493 86.4107C39.6248 86.1364 40.0708 86.1364 40.3427 86.4107C40.5711 86.638 40.6074 86.9809 40.4551 87.2479L48.8081 95.5627H96.1566V95.559Z"
          fill="url(#paint38_linear_3275_20825)"
        />
        <path
          d="M158.65 97.7897C158.925 97.5154 159.371 97.5154 159.643 97.7897C159.918 98.064 159.918 98.5078 159.643 98.7785C159.415 99.0059 159.07 99.042 158.802 98.8904L149.274 108.374H106.566L98.7462 100.59C98.4779 100.742 98.1335 100.706 97.9051 100.478C97.6295 100.204 97.6295 99.7601 97.9051 99.4894C98.1806 99.2152 98.6265 99.2152 98.8984 99.4894C99.1269 99.7168 99.1631 100.06 99.0108 100.327L106.722 108.003H149.122L158.537 98.6305C158.389 98.3635 158.421 98.017 158.65 97.7897Z"
          fill="url(#paint39_linear_3275_20825)"
        />
        <path
          d="M116.866 123.842C117.142 123.567 117.588 123.567 117.86 123.842C118.135 124.116 118.135 124.56 117.86 124.831C117.631 125.058 117.287 125.094 117.019 124.942L109.8 132.128H75.1193L62.3649 119.432H36.113C36.0296 119.728 35.7613 119.944 35.4387 119.944C35.0507 119.944 34.7353 119.63 34.7353 119.244C34.7353 118.858 35.0507 118.544 35.4387 118.544C35.7613 118.544 36.0332 118.764 36.113 119.056H62.5208L75.2752 131.752H109.648L116.754 124.679C116.602 124.412 116.638 124.069 116.866 123.842Z"
          fill="url(#paint40_linear_3275_20825)"
        />
        <path
          d="M102.698 112.094L113.121 122.47L107.382 128.183H76.4384L64.1626 115.963C63.8943 116.115 63.5499 116.079 63.3215 115.851C63.046 115.577 63.046 115.133 63.3215 114.862C63.597 114.588 64.043 114.588 64.3149 114.862C64.5433 115.09 64.5795 115.433 64.4273 115.7L76.5907 127.811H107.226L112.591 122.47L101.795 111.723H113.429L118.776 117.046C119.045 116.894 119.389 116.93 119.618 117.158C119.893 117.432 119.893 117.876 119.618 118.146C119.342 118.421 118.896 118.421 118.624 118.146C118.396 117.919 118.36 117.576 118.512 117.309L113.273 112.094H102.698Z"
          fill="url(#paint41_linear_3275_20825)"
        />
        <path
          d="M128.036 116.147C127.953 116.443 127.685 116.659 127.362 116.659C126.974 116.659 126.659 116.345 126.659 115.959C126.659 115.573 126.974 115.259 127.362 115.259C127.685 115.259 127.953 115.475 128.036 115.771H147.694L161.989 101.542H179.38C179.464 101.246 179.732 101.029 180.054 101.029C180.442 101.029 180.758 101.343 180.758 101.729C180.758 102.116 180.442 102.43 180.054 102.43C179.732 102.43 179.46 102.209 179.38 101.917H162.145L147.85 116.147H128.036Z"
          fill="url(#paint42_linear_3275_20825)"
        />
        <path
          d="M183.116 129.785C183.2 129.489 183.468 129.273 183.791 129.273C184.179 129.273 184.494 129.587 184.494 129.973C184.494 130.359 184.179 130.673 183.791 130.673C183.468 130.673 183.196 130.453 183.116 130.16H157.807L149.599 121.99C149.331 122.142 148.986 122.105 148.758 121.878C148.482 121.604 148.482 121.16 148.758 120.889C149.034 120.615 149.479 120.615 149.751 120.889C149.98 121.117 150.016 121.459 149.864 121.727L157.959 129.785H183.116Z"
          fill="url(#paint43_linear_3275_20825)"
        />
        <path
          d="M45.3322 111.023C44.9442 111.023 44.6288 110.709 44.6288 110.323C44.6288 109.936 44.9442 109.622 45.3322 109.622C45.6548 109.622 45.9267 109.843 46.0065 110.135H69.7206L76.1522 116.537H102.781L94.9502 108.742C94.6819 108.894 94.3374 108.857 94.109 108.63C93.8335 108.356 93.8335 107.912 94.109 107.641C94.3846 107.367 94.8305 107.367 95.1024 107.641C95.3308 107.869 95.3671 108.211 95.2148 108.478L103.688 116.912H75.9999L69.5683 110.51H46.0101C45.9268 110.803 45.6548 111.023 45.3322 111.023Z"
          fill="url(#paint44_linear_3275_20825)"
        />
        <path
          d="M104.585 123.434L106.416 121.612C106.263 121.345 106.299 121.002 106.528 120.774C106.803 120.5 107.249 120.5 107.521 120.774C107.793 121.049 107.797 121.493 107.521 121.763C107.293 121.991 106.948 122.027 106.68 121.875L104.741 123.806H77.0965L69.6606 116.404C69.3924 116.556 69.0479 116.52 68.8195 116.292C68.544 116.018 68.544 115.574 68.8195 115.303C69.0951 115.029 69.541 115.029 69.8129 115.303C70.0413 115.531 70.0776 115.874 69.9253 116.141L77.2523 123.434H104.585Z"
          fill="url(#paint45_linear_3275_20825)"
        />
        <path
          d="M21.8712 91.6544H11.6982C11.6148 91.9503 11.3465 92.1668 11.0239 92.1668C10.6359 92.1668 10.3205 91.8529 10.3205 91.4667C10.3205 91.0806 10.6359 90.7666 11.0239 90.7666C11.3465 90.7666 11.6148 90.9867 11.6982 91.2791H22.0235L37.6637 106.848H73.2367L78.5697 112.156H93.5465L88.5724 107.205C88.3041 107.356 87.9597 107.32 87.7313 107.093C87.4557 106.819 87.4557 106.375 87.7313 106.104C88.0068 105.83 88.4527 105.83 88.7246 106.104C88.953 106.331 88.9893 106.674 88.837 106.941L94.4492 112.528H78.4175L73.0844 107.219H37.5114L21.8712 91.6544Z"
          fill="url(#paint46_linear_3275_20825)"
        />
        <path
          d="M-3.27199 136.057C-2.94932 136.057 -2.67741 136.274 -2.59766 136.57H18.5315L23.1503 141.168H30.2526L33.3306 138.104H76.2161L79.2942 135.04H85.4719C85.5553 134.744 85.8236 134.527 86.1463 134.527C86.5342 134.527 86.8496 134.841 86.8496 135.227C86.8496 135.614 86.5342 135.928 86.1463 135.928C85.8236 135.928 85.5517 135.707 85.4719 135.415H79.4501L76.372 138.479H33.4902L30.4121 141.543H23.0017L18.3829 136.945H-2.5904C-2.67378 137.241 -2.94206 137.458 -3.26472 137.458C-3.65265 137.458 -3.96806 137.144 -3.96806 136.758C-3.96806 136.371 -3.6599 136.057 -3.27199 136.057Z"
          fill="url(#paint47_linear_3275_20825)"
        />
        <path
          d="M184.23 125.415C183.908 125.415 183.636 125.194 183.556 124.902H161.767L156.05 119.211H142.331C142.247 119.507 141.979 119.723 141.656 119.723C141.269 119.723 140.953 119.41 140.953 119.023C140.953 118.637 141.269 118.323 141.656 118.323C141.979 118.323 142.247 118.54 142.331 118.836H156.205L161.923 124.527H183.556C183.639 124.231 183.908 124.014 184.23 124.014C184.618 124.014 184.934 124.328 184.934 124.714C184.937 125.104 184.622 125.415 184.23 125.415Z"
          fill="url(#paint48_linear_3275_20825)"
        />
        <path
          d="M34.7909 128.078C34.7075 128.374 34.4392 128.591 34.1165 128.591C33.7286 128.591 33.4132 128.277 33.4132 127.891C33.4132 127.504 33.7286 127.19 34.1165 127.19C34.4392 127.19 34.7111 127.407 34.7909 127.703H62.4096L70.3711 135.628H55.9055C55.8221 135.924 55.5539 136.14 55.2312 136.14C54.8433 136.14 54.5279 135.826 54.5279 135.44C54.5279 135.054 54.8433 134.74 55.2312 134.74C55.5539 134.74 55.8258 134.957 55.9055 135.253H69.4684L62.2537 128.075H34.7909V128.078Z"
          fill="url(#paint49_linear_3275_20825)"
        />
        <path
          d="M21.9447 129.608H-0.533112L-9.27409 120.907H-23.2683C-23.3517 121.203 -23.62 121.42 -23.9427 121.42C-24.3306 121.42 -24.646 121.106 -24.646 120.72C-24.646 120.334 -24.3306 120.02 -23.9427 120.02C-23.62 120.02 -23.3517 120.236 -23.2683 120.532H-9.12182L-0.380829 129.233H21.7852L28.931 122.12H60.5341L66.4327 127.991C66.701 127.84 67.0454 127.876 67.2738 128.103C67.5494 128.378 67.5494 128.821 67.2738 129.092C66.9983 129.366 66.5524 129.366 66.2804 129.092C66.052 128.865 66.0158 128.522 66.168 128.255L60.3818 122.495H29.0869L21.9447 129.608Z"
          fill="url(#paint50_linear_3275_20825)"
        />
        <path
          d="M-2.67667 102.474L4.30596 95.5234H20.8453L31.4679 106.101C31.7361 105.949 32.0806 105.985 32.309 106.213C32.5845 106.487 32.5845 106.931 32.309 107.202C32.0334 107.476 31.5875 107.476 31.3156 107.202C31.0872 106.974 31.0509 106.631 31.2032 106.364L20.6858 95.8951H4.45822L-2.52441 102.846H-30.3063C-30.3897 103.142 -30.6579 103.358 -30.9806 103.358C-31.3685 103.358 -31.684 103.044 -31.684 102.658C-31.684 102.272 -31.3685 101.958 -30.9806 101.958C-30.6579 101.958 -30.386 102.175 -30.3063 102.47H-2.67667V102.474Z"
          fill="url(#paint51_linear_3275_20825)"
        />
        <path
          d="M-32.3002 128.176C-31.9776 128.176 -31.7056 128.392 -31.6259 128.688H-12.6938L-6.97641 134.379H27.8352L30.2534 131.972H55.1204C55.2038 131.676 55.472 131.46 55.7947 131.46C56.1826 131.46 56.498 131.774 56.498 132.16C56.498 132.546 56.1826 132.86 55.7947 132.86C55.472 132.86 55.2001 132.64 55.1204 132.348H30.4056L27.9875 134.755H-7.13593L-12.8533 129.064H-31.6295C-31.7129 129.359 -31.9812 129.576 -32.3038 129.576C-32.6918 129.576 -33.0072 129.262 -33.0072 128.876C-33.0072 128.49 -32.6881 128.176 -32.3002 128.176Z"
          fill="url(#paint52_linear_3275_20825)"
        />
        <path
          d="M-18.2695 107.422C-18.2695 107.035 -17.9541 106.721 -17.5662 106.721C-17.2435 106.721 -16.9716 106.942 -16.8918 107.234H1.66323L6.50322 102.42H20.5954C20.6787 102.124 20.947 101.907 21.2697 101.907C21.6576 101.907 21.973 102.221 21.973 102.607C21.973 102.993 21.6576 103.307 21.2697 103.307C20.947 103.307 20.6751 103.087 20.5954 102.795H8.3232C8.35583 102.849 8.3812 102.907 8.39933 102.968H20.4032L31.7291 114.242H56.6614C56.7448 113.946 57.0131 113.73 57.3357 113.73C57.7236 113.73 58.0391 114.044 58.0391 114.43C58.0391 114.816 57.7236 115.13 57.3357 115.13C57.0131 115.13 56.7411 114.91 56.6614 114.618H31.5732L20.2473 103.344H8.39933C8.31594 103.639 8.04766 103.856 7.725 103.856C7.33707 103.856 7.02166 103.542 7.02166 103.156C7.02166 103.022 7.06154 102.9 7.1268 102.795H6.65911L1.82275 107.609H-16.8882C-16.9716 107.905 -17.2399 108.122 -17.5625 108.122C-17.9541 108.122 -18.2695 107.808 -18.2695 107.422Z"
          fill="url(#paint53_linear_3275_20825)"
        />
        <path
          d="M-37.7962 113.837C-37.4735 113.837 -37.2052 114.057 -37.1219 114.35H-7.30611L3.13884 124.747H20.3561L27.4258 117.709L19.0763 109.398C18.8081 109.55 18.4636 109.514 18.2352 109.286C17.9597 109.012 17.9597 108.568 18.2352 108.298C18.5108 108.023 18.9567 108.023 19.2286 108.298C19.457 108.525 19.4933 108.868 19.341 109.135L27.9551 117.709L20.512 125.118H2.98294L-7.462 114.721H-37.1255C-37.2089 115.017 -37.4771 115.234 -37.7998 115.234C-38.1877 115.234 -38.5032 114.92 -38.5032 114.534C-38.4995 114.147 -38.1877 113.837 -37.7962 113.837Z"
          fill="url(#paint54_linear_3275_20825)"
        />
        <path
          d="M-16.5778 112.062C-16.9657 112.062 -17.2811 111.748 -17.2811 111.362C-17.2811 110.976 -16.9657 110.662 -16.5778 110.662C-16.2551 110.662 -15.9832 110.882 -15.9034 111.175H12.154L17.2804 116.277C17.5486 116.126 17.8931 116.162 18.1215 116.389C18.397 116.664 18.397 117.107 18.1215 117.378C17.8459 117.652 17.4 117.652 17.1281 117.378C16.8997 117.151 16.8634 116.808 17.0157 116.541L11.9981 111.546H-15.9034C-15.9832 111.846 -16.2551 112.062 -16.5778 112.062Z"
          fill="url(#paint55_linear_3275_20825)"
        />
        <path
          d="M5.84244 120.741L2.58316 117.497C2.31488 117.648 1.97046 117.612 1.74205 117.385C1.46652 117.111 1.46652 116.667 1.74205 116.396C2.01759 116.122 2.46351 116.122 2.73542 116.396C2.96383 116.623 3.00008 116.966 2.84781 117.233L5.9947 120.366H15.3629L10.7187 115.743C10.4504 115.894 10.106 115.858 9.87757 115.631C9.60204 115.357 9.60204 114.913 9.87757 114.642C10.1531 114.368 10.599 114.368 10.8709 114.642C11.0993 114.87 11.1356 115.212 10.9833 115.479L16.2656 120.738H5.84244V120.741Z"
          fill="url(#paint56_linear_3275_20825)"
        />
      </g>
      <path
        d="M49.4473 69.596L72.1704 91.5832H86.1269L56.4302 62.8379L49.4473 69.596Z"
        fill="url(#paint57_linear_3275_20825)"
      />
      <path
        d="M99.8203 80.0116L77.0973 58.0244H63.1406L92.8373 86.7653L99.8203 80.0116Z"
        fill="url(#paint58_linear_3275_20825)"
      />
      <path
        d="M58.7647 43.4609H46.3086V104.54H58.7647V43.4609Z"
        fill="url(#paint59_linear_3275_20825)"
      />
      <path
        d="M101.562 43.4609H89.1055V104.54H101.562V43.4609Z"
        fill="url(#paint60_linear_3275_20825)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3275_20825"
        x1="-15.3339"
        y1="2.38799"
        x2="35.9805"
        y2="2.38799"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3275_20825"
        x1="6.65813"
        y1="6.838"
        x2="86.7088"
        y2="6.838"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3275_20825"
        x1="52.3993"
        y1="3.11734"
        x2="126.292"
        y2="3.11734"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3275_20825"
        x1="69.6997"
        y1="8.70101"
        x2="169.982"
        y2="8.70101"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3275_20825"
        x1="-7.62369"
        y1="63.3458"
        x2="35.3928"
        y2="63.3458"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3275_20825"
        x1="-35.5673"
        y1="46.3438"
        x2="37.5053"
        y2="46.3438"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3275_20825"
        x1="-0.293725"
        y1="58.5471"
        x2="31.7132"
        y2="58.5471"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3275_20825"
        x1="-36.081"
        y1="12.0921"
        x2="109.858"
        y2="12.0921"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_3275_20825"
        x1="-2.82415"
        y1="18.3767"
        x2="47.8534"
        y2="18.3767"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_3275_20825"
        x1="-27.139"
        y1="14.6007"
        x2="38.3825"
        y2="14.6007"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_3275_20825"
        x1="-10.852"
        y1="24.7261"
        x2="51.3025"
        y2="24.7261"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_3275_20825"
        x1="-33.9555"
        y1="17.1716"
        x2="132.083"
        y2="17.1716"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_3275_20825"
        x1="30.9327"
        y1="47.08"
        x2="114.268"
        y2="47.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_3275_20825"
        x1="29.1737"
        y1="41.7003"
        x2="85.8849"
        y2="41.7003"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_3275_20825"
        x1="-31.7583"
        y1="30.5903"
        x2="22.342"
        y2="30.5903"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_3275_20825"
        x1="-35.4955"
        y1="47.421"
        x2="0.447685"
        y2="47.421"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_3275_20825"
        x1="47.867"
        y1="24.2155"
        x2="113.1"
        y2="24.2155"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_3275_20825"
        x1="45.312"
        y1="33.9198"
        x2="104.372"
        y2="33.9198"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_3275_20825"
        x1="41.2712"
        y1="41.1992"
        x2="80.3888"
        y2="41.1992"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_3275_20825"
        x1="54.549"
        y1="23.3973"
        x2="138.679"
        y2="23.3973"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_3275_20825"
        x1="-35.9356"
        y1="43.6159"
        x2="8.04779"
        y2="43.6159"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_3275_20825"
        x1="78.6318"
        y1="53.4125"
        x2="115.59"
        y2="53.4125"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_3275_20825"
        x1="81.5138"
        y1="46.5606"
        x2="173.646"
        y2="46.5606"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_3275_20825"
        x1="116.482"
        y1="23.2119"
        x2="180.684"
        y2="23.2119"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_3275_20825"
        x1="92.4971"
        y1="53.2103"
        x2="182.003"
        y2="53.2103"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_3275_20825"
        x1="90.9578"
        y1="30.263"
        x2="167.268"
        y2="30.263"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_3275_20825"
        x1="121.041"
        y1="38.3522"
        x2="187.501"
        y2="38.3522"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_3275_20825"
        x1="130.667"
        y1="35.871"
        x2="166.281"
        y2="35.871"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_3275_20825"
        x1="132.73"
        y1="39.3377"
        x2="147.463"
        y2="39.3377"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_3275_20825"
        x1="142.342"
        y1="85.0919"
        x2="62.2912"
        y2="85.0919"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_3275_20825"
        x1="96.6007"
        y1="81.3712"
        x2="22.7081"
        y2="81.3712"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_3275_20825"
        x1="79.3003"
        y1="86.9549"
        x2="-20.9819"
        y2="86.9549"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_3275_20825"
        x1="156.624"
        y1="141.6"
        x2="113.607"
        y2="141.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_3275_20825"
        x1="119.178"
        y1="145.44"
        x2="37.075"
        y2="145.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_3275_20825"
        x1="45.2852"
        y1="146.825"
        x2="-15.4122"
        y2="146.825"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_3275_20825"
        x1="184.567"
        y1="124.598"
        x2="111.495"
        y2="124.598"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_3275_20825"
        x1="153.398"
        y1="137.377"
        x2="-14.8253"
        y2="137.377"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_3275_20825"
        x1="149.294"
        y1="136.801"
        x2="117.287"
        y2="136.801"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_3275_20825"
        x1="185.081"
        y1="90.3461"
        x2="39.1422"
        y2="90.3461"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_3275_20825"
        x1="159.852"
        y1="102.98"
        x2="97.6975"
        y2="102.98"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_3275_20825"
        x1="118.067"
        y1="125.335"
        x2="34.7319"
        y2="125.335"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_3275_20825"
        x1="119.826"
        y1="119.954"
        x2="63.1151"
        y2="119.954"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_3275_20825"
        x1="180.758"
        y1="108.844"
        x2="126.658"
        y2="108.844"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_3275_20825"
        x1="184.496"
        y1="125.675"
        x2="148.552"
        y2="125.675"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_3275_20825"
        x1="103.688"
        y1="112.174"
        x2="44.6285"
        y2="112.174"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_3275_20825"
        x1="107.729"
        y1="119.453"
        x2="68.6112"
        y2="119.453"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_3275_20825"
        x1="94.451"
        y1="101.651"
        x2="10.3206"
        y2="101.651"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_3275_20825"
        x1="86.8514"
        y1="138.031"
        x2="-3.97484"
        y2="138.031"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_3275_20825"
        x1="184.936"
        y1="121.87"
        x2="140.952"
        y2="121.87"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_3275_20825"
        x1="70.3682"
        y1="131.666"
        x2="33.4104"
        y2="131.666"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_3275_20825"
        x1="67.4862"
        y1="124.815"
        x2="-24.6459"
        y2="124.815"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_3275_20825"
        x1="32.5181"
        y1="101.467"
        x2="-31.6843"
        y2="101.467"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_3275_20825"
        x1="56.5029"
        y1="131.464"
        x2="-33.0034"
        y2="131.464"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_3275_20825"
        x1="58.0422"
        y1="108.517"
        x2="-18.2685"
        y2="108.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_3275_20825"
        x1="27.9588"
        y1="116.606"
        x2="-38.5012"
        y2="116.606"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_3275_20825"
        x1="18.3328"
        y1="114.125"
        x2="-17.2807"
        y2="114.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_3275_20825"
        x1="16.2695"
        y1="117.592"
        x2="1.53678"
        y2="117.592"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.1072" stop-color="#141414" />
        <stop offset="0.326" stop-color="#464646" />
        <stop offset="0.6375" stop-color="#989898" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_3275_20825"
        x1="67.7871"
        y1="62.8379"
        x2="67.7871"
        y2="91.5832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#999999" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_3275_20825"
        x1="81.4804"
        y1="58.0244"
        x2="81.4804"
        y2="86.7653"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#999999" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_3275_20825"
        x1="52.5367"
        y1="43.4609"
        x2="52.5367"
        y2="104.54"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#999999" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_3275_20825"
        x1="95.3336"
        y1="43.4609"
        x2="95.3336"
        y2="104.54"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#999999" />
      </linearGradient>
      <clipPath id="clip0_3275_20825">
        <rect x="0.5" width="148" height="148" rx="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_3275_20825">
        <rect
          width="226"
          height="78.2539"
          fill="white"
          transform="translate(-38.5 -3.94727)"
        />
      </clipPath>
      <clipPath id="clip2_3275_20825">
        <rect
          width="226"
          height="78.2539"
          fill="white"
          transform="matrix(-1 0 0 1 187.5 74.3066)"
        />
      </clipPath>
    </defs>
  </svg>
);
