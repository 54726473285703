import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { VisuallyHiddenInput } from 'components/VisuallyHiddenInput';
import FilesService from 'shared/services/files.service';
import usersService from 'shared/services/users.service';
import { CameraIcon } from 'components/Icons/Camera';
import { useAuth } from 'shared/hooks/useAuth';
import Avatar from '@mui/material/Avatar';
import { colors } from 'shared/constants/theme';
import { CustomModal } from 'components/Modals/CustomModal';
import { SaveSuccessModal } from 'components/Modals/SaveSuccessModal';

export const ProfileDetailsForm = (): JSX.Element => {
  const { user, getCurrentUser } = useAuth();

  const [isLoading, setIsLoading] = useState<any>(false);
  const [changesSaved, setChangesSaved] = useState<any>(false);
  const [isEditState, setEditState] = useState<any>(false);
  const [image, setImage] = useState<any>(
    user.image ? FilesService.getFileUrl(user.image) : '',
  );
  const [isHovered, setIsHovered] = useState<any>(false);

  const { showSnackbar } = useNotification();

  const schema = yup.object().shape({
    username: yup.string().required('Username is a required field'),
    email: yup
      .string()
      .required('Email is a required field')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Invalid email address',
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({ resolver: yupResolver(schema) });

  const handleImageChange = async (e: any) => {
    try {
      const fileInput = e.target;
      const selectedImage = fileInput.files[0];

      if (selectedImage.size > 1048576) {
        fileInput.value = '';
        return showSnackbar(
          'Max file size for the profile image is 1MB',
          'error',
        );
      }

      const fileFormData = new FormData();
      fileFormData.append('file', selectedImage);

      const response = await FilesService.uploadFile(fileFormData);

      const { fileName } = response;

      await usersService.updateCurrentUser({ image: fileName });
      await getCurrentUser();
      setImage(FilesService.getFileUrl(fileName));
      showSnackbar('Image successfully updated!', 'success');
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const onSubmit: any = async (userData: any): Promise<void> => {
    setIsLoading(true);
    try {
      const payload = {
        ...userData,
      };

      await usersService.updateCurrentUser({ ...payload });
      await getCurrentUser();
      showSnackbar('Successfully updated!', 'success');
      setChangesSaved(true);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setEditState(false);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setValue('username', user.username);
    setValue('email', user.email);
    clearErrors();
    setEditState(false);
  };

  return (
    <Box
      sx={{
        background: colors.lightGrey,
        padding: '40px',
        width: {
          xs: '100%',
          sm: '100%',
          md: '70%',
          lg: '70%',
          xl: '70%',
        },
        borderRadius: '10px',
      }}
    >
      <CustomModal
        showClose
        open={changesSaved}
        onClose={() => setChangesSaved(false)}
        maxWidth="500px"
      >
        <SaveSuccessModal />
      </CustomModal>
      <form style={{ position: 'relative' }} onSubmit={handleSubmit(onSubmit)}>
        <Backdrop
          open={isLoading}
          sx={{
            borderRadius: '28px',
            position: 'absolute',
            backgroundColor: '#fff',
            opacity: '0.6 !important',
            zIndex: 3,
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px',
            fontSize: '24px',
            fontWeight: '500',
          }}
        >
          Profile Details
        </Typography>
        <Box
          sx={{ width: '100%', marginBottom: '20px' }}
          display="flex"
          flexDirection="column"
          columnGap={0.5}
          rowGap={0.5}
          marginY={0.5}
        >
          <Button
            sx={{
              bgcolor: 'transparent',
              color: '#3f96ff',
              border: '1px solid #d9d9d9',
              borderRadius: '50%',
              boxShadow: 'none',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
                boxShadow: 'none',
              },
              width: '120px',
              height: '120px',
            }}
            component="label"
            variant="contained"
          >
            <CameraIcon />
            {image ? (
              <Box
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{ width: '120px', height: '120px' }}
              >
                <Avatar
                  src={image}
                  sx={{
                    width: '120px',
                    height: '120px',
                    margin: '0',
                    borderRadius: '50%',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    opacity: isHovered ? '1' : '0',
                    transition: 'opacity 0.3s ease',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CameraIcon color="#fff" />
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <VisuallyHiddenInput
              onChange={(e) => handleImageChange(e)}
              accept=".jpg, .jpeg, .png"
              type="file"
            />
          </Button>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" rowGap="20px">
          <ControlledTextField
            control={control}
            defaultValue={user.username}
            name="username"
            label="Username"
            error={errors}
            placeholder="Username"
            disabled={!isEditState}
            maxLength={256}
            width={{
              xs: '100%',
              sm: '100%',
              md: '50%',
              lg: '50%',
              xl: '50%',
            }}
          />
          <ControlledTextField
            control={control}
            defaultValue={user.email}
            name="email"
            label="Email address"
            error={errors}
            placeholder="Email address"
            disabled={true}
            maxLength={256}
            width={{
              xs: '100%',
              sm: '100%',
              md: '50%',
              lg: '50%',
              xl: '50%',
            }}
          />
        </Box>
        {!isEditState ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '40px',
            }}
          >
            <Button
              sx={{
                background: colors.lightGreen,
                color: 'white',
                width: '90px',
                borderRadius: '10px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#1d4fbb',
                },
              }}
              onClick={() => setEditState(true)}
            >
              Edit
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '40px',
            }}
          >
            <Button
              sx={{
                marginRight: '10px',
                background: '#eeeeee',
                color: 'black',
                borderRadius: '10px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#eeeeee',
                },
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
              onClick={() => handleCancel()}
              type="button"
            >
              Cancel
            </Button>
            <Button
              sx={{
                background: colors.lightGreen,
                color: 'white',
                borderRadius: '10px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#1d4fbb',
                },
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
              type="submit"
            >
              Save changes
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};
