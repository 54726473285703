import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useMemo } from 'react';
import { getColumns } from 'components/Tables/CustomerKpiTable/columns';
import { Box } from '@mui/material';
import { getCoinInfo } from 'shared/utils/getCoinInfo';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

export const CustomerKpiTable = ({ statistics }: any) => {
  const location = useLocation();
  const { customerId } = useParams();

  const tableData: any = useMemo(() => {
    if (!statistics) {
      return null;
    }

    const { currencies } = statistics;

    return currencies.map((c: any) => {
      const { currency, totalHashrate, totalMachines, totalMinedCoins, roi } =
        c;

      return {
        totalHashrate,
        totalMachines,
        totalMinedCoins,
        roi,
        currency,
        ...getCoinInfo(currency, c.yesterdayEarnings, c.accountBalance),
      };
    });
  }, [statistics]);

  const columns = getColumns(location, customerId);

  return (
    <Box sx={{ width: '100%', marginLeft: 2 }}>
      <RedesignedTable
        tableName=""
        items={tableData}
        columns={columns}
        currentPage={1}
        totalPages={1}
        rowsPerPage={6}
        paginated={false}
      />
    </Box>
  );
};
