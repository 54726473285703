import moment from 'moment';
import { PdfActions } from 'components/PdfViewer';
import { ColumnsType } from 'components/Tables/BaseTable/types';

export const columns: ColumnsType[] = [
  {
    id: 'contract',
    label: 'Contracts',
    minWidth: 120,
    align: 'left',
    sortable: false,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 120,
    align: 'left',
    sortable: false,
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: ({ amount }) =>
      `${isNaN(amount) ? 0 : Number(amount).toLocaleString('en-US')}$`,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'left',
    sortable: false,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (value) => moment(value.date).format('MM-YYYY'),
  },
  {
    label: 'Document',
    sortable: false,
    minWidth: 150,
    align: 'left',
    render: (value) => <PdfActions pdfUrl={value.pdf} />,
  },
];
