import {
  DeleteOutline,
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { ExpandableRow } from 'components/Tables/RedesignedTable/components/ExpandableRow/ExpandableRow';
import { IconButtonStyled } from 'components/Tables/RedesignedTable/components/RedesignedTableRow/styles';
import { useState } from 'react';
import { Box, TableCell } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from '../../styles';
import { colors } from 'shared/constants/theme';

type Props = {
  row: Record<string, any>;
  columns?: ColumnsType[];
  expandColumns?: ColumnsType[];
  expandPropertyName?: string;
  index?: number;
  show?: boolean;
  onRowDelete?: any;
  isEditable?: boolean;
  handleEdit?: any;
};
export const RedesignedTableRow = ({
  index = 0,
  row,
  columns,
  expandColumns,
  expandPropertyName,
  show,
  onRowDelete,
  isEditable,
  handleEdit,
}: Props) => {
  const [expandableOpen, setExpandableOpen] = useState(false);
  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          backgroundColor:
            (index % 2 ? '#f7f8fa' : 'transparent') + ' !important',
          '& .MuiButton-contained': {
            backgroundColor: 'transparent',
            color: '#828282',
            border: '1px solid #828282',
            boxShadow: 'none',
          },
        }}
      >
        {show && (
          <StyledTableCell align="center">
            {isEditable ? (
              <EditOutlined
                onClick={() => {
                  handleEdit && handleEdit(row);
                }}
                sx={{
                  color: colors.lightGreen,
                  cursor: 'pointer',
                  fontSize: 25,
                }}
              />
            ) : (
              <></>
            )}
            <DeleteOutline
              sx={{
                color: colors.lightGreen,
                cursor: 'pointer',
                fontSize: 25,
              }}
              onClick={() => {
                onRowDelete && onRowDelete(row.id);
              }}
            />
          </StyledTableCell>
        )}
        {columns
          ? columns.map((column: any) => (
              <TableCell key={column.id} align="left">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {column.expandableIcon &&
                    (row.rowChildren ||
                      (expandColumns &&
                        expandPropertyName &&
                        row[expandPropertyName].length > 0)) && (
                      <IconButtonStyled
                        aria-label="expand row"
                        size="small"
                        onClick={() => setExpandableOpen((prev) => !prev)}
                      >
                        {expandableOpen ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButtonStyled>
                    )}
                  {column.render
                    ? column.render(row)
                    : row[column.id] !== undefined || row[column.id] !== null
                      ? row[column.id]
                      : '-'}
                </Box>
              </TableCell>
            ))
          : null}
      </TableRow>
      {(row.rowChildren ||
        (expandColumns &&
          expandPropertyName &&
          row[expandPropertyName]?.length > 0)) && (
        <ExpandableRow
          parentRow={row}
          open={expandableOpen}
          columns={expandColumns}
          items={expandPropertyName ? row[expandPropertyName] : ''}
        />
      )}
    </>
  );
};
