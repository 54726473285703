import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ChevronBackIcon } from 'components/Icons/ChevronBack';
import { ChevronForwardIcon } from 'components/Icons/ChevronForward';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNotification } from 'shared/hooks/useNotification';
import AuthService from 'shared/services/auth.service';
import { AccountInfoState, AccountTypeState } from 'pages/SignUp/types';
import * as yup from 'yup';
import { styles } from './styles';
import { Progress } from 'components/Progress';
import { colors } from 'shared/constants/theme';

type Props = {
  accountType: AccountTypeState;
  setAccountInfo: Dispatch<SetStateAction<AccountInfoState | undefined>>;
  nextStep: () => void;
  prevStep: () => void;
};
export const AccountInfo = ({
  accountType,
  setAccountInfo,
  nextStep,
  prevStep,
}: Props) => {
  const { showSnackbar } = useNotification();
  const [loading, setLoading] = useState(false);
  const schema = yup.object().shape({
    email: yup.string().required('Email is a required field').email().trim(),
    firstName: yup.string().required('Username is a required field'),
    lastName: yup.string().required('Username is a required field'),
    companyName:
      accountType === 'individual'
        ? yup.string().notRequired()
        : yup.string().required('Company name is a required field'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AccountInfoState>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (accountInfo: AccountInfoState) => {
    setLoading(true);
    try {
      const { sent } = await AuthService.signUp(accountInfo);
      if (!sent)
        return showSnackbar('Failed to send confirmation email', 'error');

      setAccountInfo(accountInfo);
      nextStep();
    } catch (e: any) {
      showSnackbar(e.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Create an account</Typography>
      <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
        <Box sx={styles.inputRow}>
          <ControlledTextField
            labelColor="black"
            required
            control={control}
            name="firstName"
            label="First name"
            error={errors}
            placeholder="Enter first name"
          />
          <ControlledTextField
            labelColor="black"
            required
            control={control}
            name="lastName"
            label="Last name"
            error={errors}
            placeholder="Enter last name"
          />
        </Box>
        {accountType === 'company' && (
          <ControlledTextField
            labelColor="black"
            required
            control={control}
            name="companyName"
            label="Company name"
            error={errors}
            placeholder="Enter company name"
          />
        )}
        <ControlledTextField
          labelColor="black"
          required
          control={control}
          name="email"
          label="Email"
          error={errors}
          placeholder="Enter email address"
        />

        <Box sx={styles.buttonsContainer}>
          <Button
            sx={{ borderColor: colors.lightGreen, color: colors.lightGreen }}
            variant="outlined"
            onClick={prevStep}
          >
            <ChevronBackIcon />
            Back
          </Button>
          <Button
            disabled={!isDirty || loading}
            className="signup-button active"
            type="submit"
            variant="outlined"
          >
            {loading ? (
              <Progress color="inherit" size={20} />
            ) : (
              <>
                Next
                <ChevronForwardIcon />
              </>
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
