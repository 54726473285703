import { colors } from 'shared/constants/theme';

export const ConfirmIcon = (props: any) => (
  <svg
    width="162"
    height="162"
    viewBox="0 0 162 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="162" height="162" rx="81" fill="#11AB5F" fill-opacity="0.1" />
    <path
      d="M66.75 81L76.25 90.5L95.25 71.5M123.75 81C123.75 104.61 104.61 123.75 81 123.75C57.3898 123.75 38.25 104.61 38.25 81C38.25 57.3898 57.3898 38.25 81 38.25C104.61 38.25 123.75 57.3898 123.75 81Z"
      stroke={colors.lightGreen}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
