import { colors } from 'shared/constants/theme';

export const KycIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="63"
    height="62"
    viewBox="0 0 63 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9147 7.75H15.998C11.7178 7.75 8.24805 11.2198 8.24805 15.5V23.25"
      stroke={colors.lightGreen}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.4141 54.2497H46.9974C51.2776 54.2497 54.7474 50.7799 54.7474 46.4997V33.583"
      stroke={colors.lightGreen}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="44.4154"
      cy="18.0833"
      r="10.3333"
      stroke={colors.lightGreen}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.6439 16.792L43.6095 20.667L41.1855 18.342"
      stroke={colors.lightGreen}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="21.1654"
      cy="34.8753"
      r="6.45833"
      stroke={colors.lightGreen}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.0814 54.2503C34.0814 50.6835 31.1899 47.792 27.623 47.792H14.7064C11.1395 47.792 8.24805 50.6835 8.24805 54.2503"
      stroke={colors.lightGreen}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
