import { colors } from 'shared/constants/theme';

export const Twitter = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_306_52708)">
        <path
          d="M10.7059 14.6747L4.69652 21.5412H1.98535L9.50558 12.946L9.6403 12.792L9.51602 12.6295L1.50591 2.15527H7.83674L12.568 8.41042L12.7533 8.6554L12.9556 8.42421L18.4397 2.15527H21.1488L14.1416 10.1641L14.0076 10.3173L14.1303 10.4796L22.4974 21.5412H16.3348L11.0926 14.6874L10.9071 14.4448L10.7059 14.6747ZM16.9437 19.9239L17.0188 20.0221H17.1423H19.0116H19.5141L19.211 19.6212L7.14479 3.6676L7.06976 3.56841H6.94539H4.93946H4.43349L4.74089 3.97029L16.9437 19.9239Z"
          fill={colors.lightGrey}
          stroke={colors.lightGrey}
          stroke-width="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_52708">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
