import { colors } from 'shared/constants/theme';

export const styles = {
  stepsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  header: {
    position: 'fixed',
    display: 'flex',
    width: '100%',
    left: 0,
    paddingLeft: '60px',
    alignItems: 'center',
    justifyContent: 'lex-start',
    background: 'black',
    padding: '20px 30px',
  },
  bodyContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  stepIndicatorContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    gap: '10px',
  },
  stepIndicator: {
    width: '32px',
    height: '4px',
    backgroundColor: '#292F38',
  },
  stepIndicatorCompleted: {
    backgroundColor: colors.lightGreen,
  },
};
