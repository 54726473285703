import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import AffiliateSubAccountForm from 'components/Forms/UserForms/AffiliateSubAccountForm';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { SectionHeading } from 'components/SectionHeading';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useCallback, useState } from 'react';
import { useAuth } from 'shared/hooks/useAuth';
import { useFetch } from 'shared/hooks/useFetch';
import { useNotification } from 'shared/hooks/useNotification';
import UsersService from 'shared/services/users.service';
import { getColumns } from 'components/Tables/AffiliateSubAccountTable/columns';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { colors } from 'shared/constants/theme';

const AffiliateSubAccountTable = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [limit, setLimit] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [userData, setUserData] = useState();
  const [delUserEmail, setDelUserEmail] = useState<string>('');
  const { user } = useAuth();
  const { showSnackbar } = useNotification();

  const handleCountItems = (event: number) => {
    setLimit(event);
  };

  const [{ users, totalPages }, updateUsers] = useFetch(
    () => UsersService.getAffiliateSubAccounts({ limit, pageNumber }),
    [limit, pageNumber],
  );

  const handleEdit = (user: any): void => {
    setShowEditModal(true);
    setUserData(user);
  };

  const handleDeleteUser = useCallback(async () => {
    if (delUserEmail) {
      await UsersService.deleteAffiliateSubAcc(delUserEmail);
      updateUsers();
      setDelUserEmail('');
      showSnackbar('Successfully Deleted', 'success');
    }
  }, [delUserEmail]);

  const columns = getColumns({
    handleEdit,
    onRowDelete: setDelUserEmail,
    role: user.role,
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          rowGap: '60px',
          marginTop: '20px',
          justifyContent: 'space-between',
        }}
      >
        <SectionHeading
          sx={{
            color: 'white',
            marginBottom: '32px',
          }}
          name="Sub accounts"
        />

        <Box display="flex" columnGap={2}>
          {user.role === UserRole.AFFILIATE && (
            <Box
              sx={{
                height: '40px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#ffffff',
                border: `1px solid ${colors.lightGreen}`,
                borderRadius: '20px !important',
                color: '#000000',
                padding: '5px 15px',
              }}
              className="addAdminStyles"
              onClick={() => setShowEditModal(true)}
            >
              <AddCircleOutline
                sx={{
                  cursor: 'pointer',
                  marginRight: '10px',
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                }}
              />
              Add
            </Box>
          )}
        </Box>
      </Box>
      <RedesignedTable
        items={users}
        columns={columns}
        totalPages={totalPages}
        rowsPerPage={limit}
        setRowsPerPage={handleCountItems}
        currentPage={pageNumber}
        setCurrentPage={setPageNumber}
      />

      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <AffiliateSubAccountForm
          setShowModal={setShowEditModal}
          onFinish={updateUsers}
          users={users}
          editData={userData}
        />
      </CustomModal>

      <CustomModal open={!!delUserEmail} onClose={() => setDelUserEmail('')}>
        <Box className="container">
          <DeleteModal
            title="user"
            onClick={handleDeleteUser}
            onCancel={() => setDelUserEmail('')}
          />
        </Box>
      </CustomModal>
    </>
  );
};

export default AffiliateSubAccountTable;
