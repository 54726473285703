import { ColumnsType } from 'components/Tables/BaseTable/types';

export const machineStatus: any = {
  Active: 'Active',
  Unplugged: 'Unplugged',
  Under_Maintenance: 'Under Maintenance',
};

export const columns: ColumnsType[] = [
  {
    id: 'serialNumber',
    label: 'Serial Number',
    minWidth: 100,
    align: 'left',
    sortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: ({ status }: any) => `${machineStatus[status]}`,
  },
  {
    id: 'plugDate',
    label: 'Plugged Date',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
];
