import { colors } from 'shared/constants/theme';

export const HearstIcon = ({ width, height }: any) => (
  <svg
    width={width ? width : '48'}
    height={height ? height : '52'}
    viewBox="0 0 48 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.14844 22.2506L22.4938 40.9694H34.3757L9.09337 16.4971L3.14844 22.2506Z"
      fill={colors.lightGrey}
    />
    <path
      d="M46.034 31.1158L26.6887 12.397H14.8066L40.089 36.8656L46.034 31.1158Z"
      fill={colors.lightGrey}
    />
    <path d="M11.0811 0H0.476562V52H11.0811V0Z" fill={colors.lightGrey} />
    <path d="M47.5226 0H36.918V52H47.5226V0Z" fill={colors.lightGrey} />
  </svg>
);
