import { Box, Grid, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { HashrateChart } from 'components/Charts/HashrateChart';
import { subtitleStyles } from 'components/CustomerDetails/Activity/styles';
import {
  hashrateChartOption,
  hashrateChartOptionSelected,
} from 'pages/Customer/Operation/Chain/styles';
import { useState } from 'react';
import { colors } from 'shared/constants/theme';
import { Active } from 'shared/utils/enum/activeEnums';

const HashrateSortingHeader = (props: any) => {
  const { activeChart, setActiveChart } = props;
  return (
    <Box>
      <Typography sx={subtitleStyles} variant="h5" fontWeight="500">
        Hashrate
      </Typography>
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          columnGap: 1.5,
        }}
      >
        <Typography
          onClick={() => setActiveChart(Active.DAY)}
          sx={{
            color: activeChart === Active.DAY ? colors.lightGrey : '#000',
            fontWeight: 500,
            ...(activeChart === Active.DAY
              ? hashrateChartOptionSelected
              : hashrateChartOption),
          }}
        >
          One Day
        </Typography>
        <Typography
          onClick={() => setActiveChart(Active.HOUR)}
          sx={{
            color: activeChart === Active.HOUR ? colors.lightGrey : '#000',
            fontWeight: 500,
            ...(activeChart === Active.HOUR
              ? hashrateChartOptionSelected
              : hashrateChartOption),
          }}
        >
          One Hour
        </Typography>
      </Box>
    </Box>
  );
};

export const Hashrate = (props: any) => {
  const { hashrateData } = props;
  const [activeChart, setActiveChart] = useState(Active.DAY);

  return (
    <>
      <Grid>
        <InfoCard
          info="Your Hashrate"
          styles={{
            height: props.height ? props.height : '690px',
          }}
          subtitle={
            <HashrateSortingHeader
              activeChart={activeChart}
              setActiveChart={setActiveChart}
            />
          }
          showDivider={false}
          fullHeight
          content={
            <HashrateChart
              hashrateData={hashrateData}
              activeChart={activeChart}
            />
          }
        />
      </Grid>
    </>
  );
};
