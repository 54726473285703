import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { colors } from 'shared/constants/theme';
import { formatCurrency } from 'shared/utils/formatter';

export const WalletCoin = (props: any): JSX.Element => {
  const { coin, chainsSummaryValue } = props;

  const calculateAndFormatPercentage = (part: any, total: any) => {
    const percentage = (part / total) * 100;
    const roundedPercentage = percentage.toFixed(0);

    return `${roundedPercentage}%`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        rowGap: '10px',
        columnGap: '10px',
        alignItems: 'center',
        width: '260px',
      }}
    >
      <Avatar src={coin.logo} sx={{ width: '52px', height: '52px' }} />
      <Box>
        <Typography sx={{ color: '#7B7B7B', fontSize: '14px' }}>
          {coin.name}
        </Typography>
        <Box sx={{ display: 'flex', columnGap: '10px' }}>
          <Typography
            sx={{
              color: 'secondary.main',
              fontSize: '24px',
              fontWeight: '500',
            }}
          >
            {formatCurrency(coin.usdValue)}
          </Typography>
          <Typography
            sx={{
              color: colors.darkBlue,
              fontSize: '16px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'end',
              marginBottom: '3px',
            }}
          >
            {calculateAndFormatPercentage(coin.usdValue, chainsSummaryValue)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
