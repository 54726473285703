import { createContext } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const ThemeContext = createContext({});

export const CustomThemeProvider = (props: any) => {
  const { children } = props;

  const theme = createTheme({
    palette: {
      primary: {
        main: '#101723',
      },
      secondary: {
        main: colors.lightGreen,
      },
    },
    typography: {
      fontFamily: 'Poppins, Work Sans',
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: 8,
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: '#fff',
            textTransform: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: 'black',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
