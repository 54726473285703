import { Box, Typography } from '@mui/material';
import { modalContainer, textStyles } from './styles';
import hearstBlueIcon from 'assets/images/hearsBluelogo.svg';
import { HearstIconBackground } from 'components/Icons/HearstIconBackground';

export const WelcomeModal = (): JSX.Element => {
  return (
    <Box sx={modalContainer}>
      <HearstIconBackground />
      <Typography sx={textStyles}>
        Thanks for verifying your identity. You will shortly get full access to
        the dashboard with detailed information on all your contracts.
      </Typography>
    </Box>
  );
};
