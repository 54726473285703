import { colors } from 'shared/constants/theme';

export const SvgComponentSee = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1233 9.87975C15.2943 11.0508 15.2943 12.9518 14.1233 14.1248C12.9523 15.2958 11.0512 15.2958 9.87825 14.1248C8.70725 12.9538 8.70725 11.0527 9.87825 9.87975C11.0512 8.70675 12.9512 8.70675 14.1233 9.87975"
      stroke={colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 12C3 11.341 3.152 10.689 3.446 10.088V10.088C4.961 6.991 8.309 5 12 5C15.691 5 19.039 6.991 20.554 10.088V10.088C20.848 10.689 21 11.341 21 12C21 12.659 20.848 13.311 20.554 13.912V13.912C19.039 17.009 15.691 19 12 19C8.309 19 4.961 17.009 3.446 13.912V13.912C3.152 13.311 3 12.659 3 12Z"
      stroke={colors.lightGrey}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
