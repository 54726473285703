import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ChevronBackIcon } from 'components/Icons/ChevronBack';
import { ChevronForwardIcon } from 'components/Icons/ChevronForward';
import { SvgComponentHide } from 'components/Icons/Hide';
import { SvgComponentSee } from 'components/Icons/See';
import { CreatePasswordState } from 'pages/SignUp/types';
import { useNotification } from 'shared/hooks/useNotification';
import AuthService from 'shared/services/auth.service';
import { styles } from './styles';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LockIcon } from 'components/Icons/Lock';
import { colors } from 'shared/constants/theme';

type Props = {
  nextStep: () => void;
  prevStep: () => void;
  email: string;
  emailToken: string;
  setAccessToken: Dispatch<SetStateAction<string | undefined>>;
};
export const CreatePassword = ({
  nextStep,
  prevStep,
  email,
  emailToken,
  setAccessToken,
}: Props) => {
  const { showSnackbar } = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirm: yup
      .string()
      .required('Confirm your password')
      .test(
        'is-match',
        'Password confirmation should match your password',
        function (value) {
          const { password }: CreatePasswordState = this.parent;
          return value === password;
        },
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CreatePasswordState>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ password }: CreatePasswordState) => {
    try {
      const { accessToken } = await AuthService.resetPassword({
        password,
        email,
        emailToken,
      });
      setAccessToken(accessToken);
      nextStep();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Create password</Typography>
      <Typography sx={styles.subtitle}>
        Choose password for your account
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
        <ControlledTextField
          labelColor="black"
          control={control}
          name="password"
          label="Password"
          placeholder="Enter password"
          error={errors}
          required
          type={showPassword ? 'text' : 'password'}
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <SvgComponentSee /> : <SvgComponentHide />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="#000000" />
                </InputAdornment>
              ),
            },
          }}
        />
        <ControlledTextField
          labelColor="black"
          control={control}
          name="confirm"
          label="Confirm password"
          placeholder="Confirm password"
          error={errors}
          required
          type={showConfirm ? 'text' : 'password'}
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirm(!showConfirm)}>
                    {showConfirm ? <SvgComponentSee /> : <SvgComponentHide />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="#000000" />
                </InputAdornment>
              ),
            },
          }}
        />

        <Box sx={styles.buttonsContainer}>
          <Button
            sx={{ borderColor: colors.lightGreen, color: colors.lightGreen }}
            variant="outlined"
            onClick={prevStep}
          >
            <ChevronBackIcon />
            Back
          </Button>
          <Button
            disabled={!isDirty}
            className="signup-button active"
            type="submit"
            variant="outlined"
          >
            Confirm
            <ChevronForwardIcon />
          </Button>
        </Box>
      </form>
    </Box>
  );
};
