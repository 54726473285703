export const styles = {
  container: {
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  title: { color: 'black', fontSize: '48px', fontWeight: 500 },
  subtitle: {
    color: 'black',
    fontSize: '16px',
    marginTop: '16px',
    marginBottom: '42px',
    fontWeight: 400,
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
};
