import { colors } from 'shared/constants/theme';

export const tabsContainer = {
  backgroundColor: '#202a25',
  borderRadius: '20px',
  padding: '10px',
  display: 'flex',
};

export const activeTabStyles = {
  width: '100%',
  textAlign: 'center',
  padding: {
    xs: '8px',
    sm: '8px',
    md: '12px',
    lg: '12px',
    xl: '12px',
  },
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
  color: colors.darkBlue,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const tabStyles = {
  width: '100%',
  textAlign: 'center',
  padding: {
    xs: '8px',
    sm: '8px',
    md: '12px',
    lg: '12px',
    xl: '12px',
  },
  color: '#FFFFFF',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const logoContainer = {
  display: {
    xs: 'none',
    sm: 'flex',
    md: 'flex',
    lg: 'flex',
    xl: 'flex',
  },
};

export const logoStyles = { width: '24px', height: '24px', marginRight: '8px' };
