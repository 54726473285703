export const CodeIcon = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4692 6.429V4.969C14.4692 3.936 13.5883 3 12.5002 3C11.4122 3 10.5312 3.881 10.5312 4.969V6.428"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8133 10.9997H10.1833C9.56831 10.9997 9.07031 10.5017 9.07031 9.88671V7.54171C9.07031 6.92671 9.56831 6.42871 10.1833 6.42871H14.8143C15.4293 6.42871 15.9273 6.92671 15.9273 7.54171V9.88671C15.9273 10.5017 15.4283 10.9997 14.8133 10.9997V10.9997Z"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 21H6C4.067 21 2.5 19.433 2.5 17.5V17.5C2.5 15.567 4.067 14 6 14H19C20.933 14 22.5 15.567 22.5 17.5V17.5C22.5 19.433 20.933 21 19 21Z"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.744 17.499C18.744 17.364 18.635 17.255 18.5 17.256C18.365 17.256 18.256 17.365 18.256 17.5C18.256 17.635 18.365 17.744 18.5 17.744C18.635 17.744 18.744 17.635 18.744 17.499"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.744 17.499C14.744 17.364 14.635 17.255 14.5 17.256C14.365 17.256 14.256 17.365 14.256 17.5C14.256 17.635 14.365 17.744 14.5 17.744C14.634 17.744 14.744 17.635 14.744 17.499"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.744 17.499C10.744 17.364 10.635 17.255 10.5 17.256C10.365 17.256 10.256 17.365 10.256 17.5C10.256 17.635 10.365 17.744 10.5 17.744C10.634 17.744 10.744 17.635 10.744 17.499"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.744 17.499C6.744 17.364 6.635 17.255 6.5 17.256C6.365 17.256 6.256 17.365 6.256 17.5C6.256 17.635 6.365 17.744 6.5 17.744C6.634 17.744 6.744 17.635 6.744 17.499"
      stroke={props.color ? props.color : 'black'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
