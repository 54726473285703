import { Box, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { MinerChart } from 'components/Charts/MinerChart';
import { DistributionPerCountry } from 'components/DestributionPerCountry';
import { SectionHeading } from 'components/SectionHeading';
import { itemHeaderStyles } from 'pages/Customer/Operation/Miners/styles';
import countryCodes from 'shared/constants/country-codes.json';

export const Miners = (props: any) => {
  const { machineByLocation, machinePerTypeData } = props;

  const location = (country: any) => {
    switch (country) {
      case 'AE':
        return 'UAE';
      default:
        return countryCodes.countries.find((c: any) => c.alpha2 === country)
          ?.country;
    }
  };

  return (
    <>
      <SectionHeading
        sx={{
          marginTop: 4,
          marginBottom: 4,
        }}
        name="Miners distribution"
      />
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        {machinePerTypeData && (
          <InfoCard
            showDivider={false}
            showTime={false}
            fullHeight
            title=""
            styles={{ width: '100%', height: '350px' }}
            hasAdditionalSpace={false}
            content={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '30px',
                }}
              >
                <Typography sx={itemHeaderStyles}>
                  Distribution per miner type
                </Typography>
                <MinerChart items={machinePerTypeData} />
              </Box>
            }
          />
        )}
        {machineByLocation && (
          <InfoCard
            showDivider={false}
            showTime={false}
            fullHeight
            title=""
            styles={{ width: '100%', height: '350px' }}
            hasAdditionalSpace={false}
            content={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '30px',
                }}
              >
                <Typography sx={itemHeaderStyles}>
                  Distribution per country
                </Typography>
                <DistributionPerCountry
                  items={machineByLocation}
                  getCountry={location}
                />
              </Box>
            }
          />
        )}
      </Box>
    </>
  );
};
