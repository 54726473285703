import { useEffect, useMemo, useState } from 'react';
import { Layout } from 'components/Layout';
import { Box, Skeleton, SvgIcon, Typography } from '@mui/material';
import WalletService from 'shared/services/wallet.service';
import { WalletCoin } from 'components/Wallet/WalletDetailsCoin';
import { SummaryWalletCard } from 'components/Wallet/SummaryWalletCard';
import WalletIcon from 'assets/logos/walletIcon.svg';
import { CoinsTable } from 'components/Tables/WalletTable';
import { WalletProtocols } from './WalletProtocols.tsx';
import {
  formatCurrency,
  formatCurrencyWithoutCommas,
} from 'shared/utils/formatter';
import { useParams } from 'react-router';
import { CopyIcon } from 'components/Icons/Copy';
import { useNotification } from 'shared/hooks/useNotification';
import { useAuth } from 'shared/hooks/useAuth';
import { HearstIcon } from 'components/Icons/HearstIcon';
import { Progress } from 'components/Progress';
import { colors } from 'shared/constants/theme';

export const WalletDetails = () => {
  const [loading, setLoading] = useState(false);
  const [coins, setCoins] = useState<any[]>([]);
  const [chains, setChains] = useState<any[]>([]);
  const [protocols, setProtocols] = useState<any[]>([]);
  const [summaryTotalCost, setSummaryTotalCost] = useState(0);
  const [chainsSummaryValue, setChainsSummaryValue] = useState(0);
  const [walletEmpty, setWalletEmpty] = useState(false);
  const [ethWallet, setEthWallet] = useState('');

  const { customerId } = useParams();
  const { user } = useAuth();

  const { showSnackbar } = useNotification();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    showSnackbar('Address Copied', 'success');
  };

  const getWalletData = async () => {
    setLoading(true);
    try {
      const response = await WalletService.getWalletData({ customerId });

      const {
        coins,
        chains,
        protocols,
        walletAddress,
        coinsTotalCost,
        chainsSummaryValue,
      } = response;

      setCoins(coins);
      setChains(chains);
      setProtocols(protocols);
      setEthWallet(walletAddress);
      setSummaryTotalCost(coinsTotalCost);
      setChainsSummaryValue(chainsSummaryValue);
    } catch (err: any) {
      if (err.error === 'No wallet address found') {
        setWalletEmpty(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWalletData();
  }, []);

  return (
    <Layout backgroundHeight="500px">
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 256px)',
            display: 'flex',
            marginTop: '150px',
            justifyContent: 'center',
            textAlign: 'center',
            color: '#fff',
            fontSize: '24px',
            fontWeight: '500',
          }}
        >
          <Progress />
        </Box>
      ) : !walletEmpty ? (
        <Box>
          <Box
            sx={{
              padding: '20px',
              background: `linear-gradient(105.72deg, ${colors.lightGreen} -1.13%, #028BE7 108.29%)`,
              borderRadius: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '40px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '20px',
                columnGap: '20px',
              }}
            >
              <Typography
                sx={{ fontSize: '20px', fontWeight: '500', color: '#fff' }}
              >
                Net Worth
              </Typography>
              <Typography
                sx={{ fontSize: '32px', fontWeight: '600', color: '#fff' }}
              >
                {`${formatCurrencyWithoutCommas(chainsSummaryValue)} USD`}
              </Typography>
              <Box
                sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '10px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginRight: '10px',
                    width: {
                      xs: '200px',
                      sm: '200px',
                      md: '100%',
                      lg: '100%',
                      xl: '100%',
                    },
                  }}
                >
                  {user.ethWalletAddress ? user.ethWalletAddress : ethWallet}
                </Typography>
                {user.ethWalletAddress || ethWallet ? (
                  <SvgIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleCopy(
                        user.ethWalletAddress
                          ? user.ethWalletAddress
                          : ethWallet,
                      );
                    }}
                  >
                    <CopyIcon />
                  </SvgIcon>
                ) : (
                  <Skeleton variant="rectangular" height={40} />
                )}
              </Box>
            </Box>
            <Box sx={{ marginRight: '40px' }}>
              <HearstIcon />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGrey,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '20px',
              flexWrap: 'wrap',
              padding: '20px',
              rowGap: '35px',
              columnGap: '20px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '10px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '28px',
                    color: colors.darkBlue,
                    fontWeight: '500',
                  }}
                >
                  Portfolio
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: colors.lightGrey,
                  display: 'flex',
                  borderRadius: '20px',
                  flexWrap: 'wrap',
                  rowGap: '30px',
                  columnGap: '20px',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      rowGap: '30px',
                      columnGap: '16px',
                      width: '100%',
                      maxWidth: {
                        xs: '260px',
                        sm: '536px',
                        md: '812px',
                        lg: '1088px',
                        xl: '1364px',
                      },
                    }}
                  >
                    {chains.map((coin) => (
                      <Box>
                        <WalletCoin
                          key={coin.id}
                          coin={coin}
                          chainsSummaryValue={chainsSummaryValue}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '10px',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '28px',
                    color: colors.darkBlue,
                    fontWeight: '500',
                  }}
                >
                  Protocols
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '35px',
                  display: 'flex',
                  columnGap: '16px',
                  rowGap: '16px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      columnGap: '16px',
                      rowGap: '16px',
                      flexWrap: 'wrap',
                      width: '100%',
                      maxWidth: {
                        xs: '260px',
                        sm: '536px',
                        md: '812px',
                        lg: '1088px',
                        xl: '1364px',
                      },
                    }}
                  >
                    <SummaryWalletCard
                      name="Wallet"
                      value={formatCurrency(summaryTotalCost)}
                      logo={WalletIcon}
                    />
                    {protocols.map((protocol) => (
                      <SummaryWalletCard
                        key={protocol.id}
                        name={protocol.name}
                        value={formatCurrency(protocol.summaryValue, false)}
                        logo={protocol.logo}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: '65px' }}>
            <CoinsTable
              coins={coins}
              summaryCost={formatCurrency(summaryTotalCost)}
            />
          </Box>
          <WalletProtocols protocols={protocols} />
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 256px)',
            display: 'flex',
            marginTop: '150px',
            justifyContent: 'center',
            textAlign: 'center',
            color: '#fff',
            fontSize: '24px',
            fontWeight: '500',
          }}
        >
          Ethereum wallet which is needed to display crypto portfolio is not
          specified
        </Box>
      )}
    </Layout>
  );
};
