import { subtitleStyles } from 'components/Cards/styles';
import { useMemo } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { subtitleTextStyle } from '../styles';
import { ChainChart } from 'components/Charts/ChainChart';

const formatValue = (value: any, type: string) =>
  type === 'revenue' ? `$${value}` : `${value} T`;

const Statistic = (props: any) => {
  const { name, value } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
      }}
    >
      <Typography sx={subtitleTextStyle} fontWeight={500}>
        {name}
      </Typography>
      {value !== null ? (
        <Typography sx={subtitleTextStyle} fontWeight={500} color="secondary">
          {value}
        </Typography>
      ) : (
        <Skeleton variant="rectangular" height={25} width={100} />
      )}
    </Box>
  );
};

export const ChainCard = (props: any) => {
  const { data, type = 'revenue' } = props;

  const chainType = useMemo(
    () => ({
      name: type === 'revenue' ? 'Revenue Synthesis' : 'Chain Difficulty',
      tooltip: type === 'revenue' ? 'Revenue Synthesis' : 'Chain Difficulty',
    }),
    [type],
  );

  const chainTypeStatistics = useMemo(
    () => ({
      thisMonth: data
        ? formatValue(
            data[type].beginningOfMonthAverage.toLocaleString('en-US'),
            type,
          )
        : null,
      pastTwoYears: data
        ? formatValue(
            data[type].lastTwoYearsAverage.toLocaleString('en-US'),
            type,
          )
        : null,
      yesterday: data
        ? formatValue(data[type].yesterdayAverage.toLocaleString('en-US'), type)
        : null,
    }),
    [data, type],
  );

  return (
    <InfoCard
      info={chainType.tooltip}
      styles={{
        height: '550px',
      }}
      fullHeight
      subtitle={
        <Box>
          <Typography sx={subtitleStyles} variant="h5" fontWeight="500">
            {chainType.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
            }}
          >
            <Typography sx={subtitleTextStyle} color="#000" fontWeight={500}>
              Live
            </Typography>
            {chainTypeStatistics.yesterday ? (
              <Typography
                sx={subtitleTextStyle}
                color="secondary.main"
                fontWeight={500}
              >
                {chainTypeStatistics.yesterday}
              </Typography>
            ) : (
              <Skeleton variant="rectangular" height={30} width={70} />
            )}
          </Box>
        </Box>
      }
      content={
        <Box height="250px">
          <ChainChart type={type} items={data ? data.chart : []} />
          <Box marginTop={3}>
            <Statistic
              type={type}
              name="This Month"
              value={chainTypeStatistics.thisMonth}
            />
            <Statistic
              type={type}
              name="Past 2 Years"
              value={chainTypeStatistics.pastTwoYears}
            />
          </Box>
        </Box>
      }
    />
  );
};
