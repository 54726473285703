export const IndividualIcon = (props: any) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.82422 33.3333C8.82422 29.1667 12.1576 26 16.1576 26H24.6576C28.8243 26 31.9909 29.3333 31.9909 33.3333"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.4888 8.66797C28.3221 11.5013 28.3221 16.0013 25.4888 18.668C22.6554 21.3346 18.1554 21.5013 15.4888 18.668C12.8221 15.8346 12.6554 11.3346 15.4888 8.66797C18.3221 6.0013 22.6554 6.0013 25.4888 8.66797"
      stroke={props.selected ? 'white' : 'black'}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
