import { ErrorOutline } from '@mui/icons-material';
import { Box, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { Card } from 'components/Cards/Card';
import { getTimeZone } from 'shared/utils/getTimeZone';
import moment from 'moment';
import { subtitleStyles } from '../styles';

export const InfoCard = (props: any) => {
  const {
    subtitle,
    showDivider = true,
    showTime = true,
    info,
    styles,
    content,
    fullHeight,
    headerColor,
    infoIconColor,
    hasOverflow,
    hasAdditionalSpace = true,
    link,
    linkText,
  } = props;

  return (
    <Card styles={styles}>
      {(subtitle || info || showDivider) && (
        <Box
          sx={{
            ...(showDivider
              ? { borderBottom: '1px solid #ECECEC', borderRadius: 0 }
              : {}),
          }}
        >
          <Box
            sx={{
              padding: hasAdditionalSpace ? 3 : 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {subtitle ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    sx={{
                      ...subtitleStyles,
                      color: headerColor,
                    }}
                    variant="h5"
                    fontWeight="500"
                  >
                    {subtitle}
                  </Typography>
                  {link && linkText && (
                    <Link target="blank" href={link}>
                      <Typography color="#828282">{linkText}</Typography>
                    </Link>
                  )}
                </Stack>
              ) : (
                <Skeleton variant="rectangular" height={40} width="100%" />
              )}
              {showTime && (
                <Typography
                  marginTop={3}
                  marginBottom={showDivider && 1}
                  color="#828282"
                >
                  {moment().format(`dddd - MMM D, HH:mm [${getTimeZone()}]`)}
                </Typography>
              )}
            </Box>
            {info && (
              <Tooltip title={info} arrow enterTouchDelay={0}>
                <ErrorOutline
                  sx={{
                    color: infoIconColor ? infoIconColor : '#828282',
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      )}

      <Box
        sx={{
          overflowY: 'auto',
          padding: 3,
          height: fullHeight && '100%',
          overflow: hasOverflow && 'auto',
        }}
      >
        {content}
      </Box>
    </Card>
  );
};
