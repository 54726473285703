import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Avatar, Box, Typography } from '@mui/material';
import { formatCurrency, formatDecimals } from 'shared/utils/formatter';
import { colors } from 'shared/constants/theme';

const handleCoinPrice = (price: any) => {
  if (price === 0) {
    return '$0';
  }

  if (price >= 1) {
    return formatCurrency(price);
  }
  if (price > 0.0001) {
    return `$${price.toFixed(4)}`;
  }

  return `$${formatDecimals(price)}`;
};

const handleAmount = (token: any) => {
  const amount = token.amount;
  if (amount > 1 && amount < 99) {
    const decimalPlaces = amount.toString().split('.')[1]?.length || 0;
    const formatPlaces = Math.min(decimalPlaces, 4);
    return amount.toFixed(formatPlaces);
  } else if (amount >= 100 && amount.toString().includes('.')) {
    return amount.toFixed(1);
  } else if (amount >= 1000) {
    const formattedNum = amount.toFixed(1);
    const parts = formattedNum.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (parts[1] === '0') {
      return parts[0];
    }
    return parts.join('.');
  }

  return `${formatDecimals(amount)}`;
};

export const columns: ColumnsType[] = [
  {
    id: 'token',
    label: 'Token',
    minWidth: 180,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '12px',
          marginLeft: '20px',
        }}
      >
        <Avatar src={token?.logo} sx={{ width: '20px', height: '20px' }} />
        <Typography
          sx={{ fontSize: '16px', color: colors.darkBlue, fontWeight: '500' }}
        >
          {token?.optimizedSymbol}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: ({ price }: any) => (
      <div
        dangerouslySetInnerHTML={{ __html: `${handleCoinPrice(price)}` }}
      ></div>
    ),
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <div dangerouslySetInnerHTML={{ __html: `${handleAmount(token)}` }}></div>
    ),
  },
  {
    id: 'value',
    label: 'USD Value',
    minWidth: 120,
    align: 'right',
    sortable: false,
    render: (token: any) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: '6px',
          width: '100%',
        }}
      >
        {formatCurrency(token?.totalCost)}
      </Box>
    ),
  },
];
