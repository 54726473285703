import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFormInput } from 'components/Forms/InvoiceForm/types';
import { columns } from './columns';
import { useNotification } from 'shared/hooks/useNotification';
import InvoicesService from 'shared/services/invoices.service';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { Box } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const AllInvoicesTable = (props: any) => {
  const { contracts, dates } = props;
  const [invoices, setInvoices] = useState<IFormInput[] | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [search, setSearch] = useState('');

  const { customerId } = useParams();

  const { showSnackbar } = useNotification();

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const fetchInvoices = async () => {
    setInvoices(null);
    try {
      const response = await InvoicesService.getInvoices({
        pageNumber: currentPage,
        limit: rowsPerPage,
        contractIds: contracts,
        dates,
        customerId,
        search,
      });

      const { invoices, totalPages } = response;
      setInvoices(invoices);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    if (!contracts || !dates) return;
    fetchInvoices();
  }, [contracts, dates, currentPage, rowsPerPage, search]);

  return (
    <Box
      sx={{
        marginTop: '20px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderRadius: '20px',
        padding: '20px',
        backgroundColor: colors.lightGrey,
      }}
    >
      <RedesignedTable
        info="Your invoices"
        items={invoices}
        columns={columns}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        rowColor={(row) => row.status === 'Unpaid' && '#EB4C42'}
        searchable
        wrapped={false}
        onSearch={setSearch}
      />
    </Box>
  );
};
