import { useMemo } from 'react';
import { IProps } from './types';
import { Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { colors } from 'shared/constants/theme';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#fff',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.lightGreen,
  },
}));

export const DistributionPerCountry = ({
  items,
  getCountry,
}: IProps): JSX.Element => {
  const data = useMemo(() => {
    const numbers = items.map((item) => item.machineNumber);
    const highestNumber = Math.max(...numbers);

    return items.map((item) => {
      return { ...item, percent: (item.machineNumber / highestNumber) * 100 };
    });
  }, [items]);

  return !items.length ? (
    <Skeleton height={300} variant="rectangular" />
  ) : (
    <>
      {data.map((item) => {
        return (
          <Box
            key={item.machineNumber + item.percent}
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}
            >
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${item.location.toLowerCase()}.svg`}
                style={{ width: '30px', height: '30px' }}
              />
              <Typography fontWeight={500}>
                {getCountry(item.location)}
              </Typography>
              <Typography fontWeight={500}>{item.machineNumber}</Typography>
            </Box>
            <BorderLinearProgress variant="determinate" value={item.percent} />
          </Box>
        );
      })}
    </>
  );
};
